import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';

export const Wrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  marginBottom: '120px',

  '@media (max-width: 750px)': {
    marginBottom: '56px'
  }
}));

export const ContentWrapper = styled(Box)(({ theme }) => ({
  marginTop: '24px',
}));

export const ImgWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '864px',
  marginTop:'103px',
  '& img': {
    width: '100%'
  },
  '@media (max-width: 750px)': {
    marginTop:'40px',
  }
}));


export const Text = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  fontFamily: 'Open Sans Regular',
  color: '#FFFFFF',

  '&.MuiTypography-p': {
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '30px'
  },
  ///
  '@media (max-width: 750px)': {
    '&.MuiTypography-p': {
      fontSize: '16px',
      lineHeight: '24px'
    },
  }
}));
