import React from 'react'
import { Controller } from 'react-hook-form'
import upload_pic from '../../../assets/images/home/upload.svg'
import './form.css'
import { Box } from '@mui/material'

const ControlledUploadFileField = ({ control, name, rules, error, helperText, label }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box
          sx={{
            fontFamily: 'Open Sans Regular',
            fontSize: '14px',
            fontWeight: 600,
            paddingX: 1,
            color: '#FFF'
          }}>
          {label}
        </Box>
        {error && <Box sx={{ color: '#FF9E9E' }}>{helperText}</Box>}
      </Box>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { value, onChange, ...field } }) => {
          return (
            <div className='upload_container mr_content'>
              <img src={upload_pic} alt='upload' />
              <div className='upload'>
                <label htmlFor={name} className='btn-warning'>
                  Click here to upload the file
                </label>
                <input
                  {...field}
                  type='file'
                  id={name}
                  accept='application/pdf'
                  className='input_file'
                  name={name}
                  style={{ display: 'none' }}
                  onChange={event => {
                    onChange(event.target.files[0])
                  }}
                />
                {value ? <p>{value.name} Uploaded successfully.</p> : <p>PDF (max. 500 kB)</p>}
              </div>
            </div>
          )
        }}
      />
    </Box>
  )
}

export default ControlledUploadFileField
