import React from 'react'
import { Box, Stack } from '@mui/material'
import { FormText } from '../PreAssessmentForm.styled'
import { ControlledRadioButton } from '../../../components/inputs/ControlledRadioButton'

const genderOptions = [
  { label: 'she/her', value: 'she/her' },
  { label: 'he/his', value: 'he/his' },
  { label: 'they/them', value: 'they/them' },
  { label: 'she/them', value: 'she/them' },
  { label: 'he/them', value: 'he/them' }
]

const GenderStep = ({ control, errors }) => {
  return (
    <Box>
      <FormText variant='h1'>Please select your preferred pronouns</FormText>
      <Box >
        <Stack direction={'column'} spacing={3}>
          <ControlledRadioButton
            control={control}
            name='gender'
            options={genderOptions}
            defaultValue={'she/her'}
          />
        </Stack>
      </Box>
    </Box>
  )
}

export default GenderStep
