import React from 'react';
import Container from '../../../components/General/Container';
import { Wrapper } from '../Fags.styled';
import { GeneralText } from '../../../components/General/Text';
import AccordionStyled from '../Accordion';

const atTrainingFaqs = [
  {
    title: 'How can I book my training?',
    content:
      `You can book your training through the Study Tech Student App, by emailing <a href='att@study.tech' class='email-link-dark' aria-label='att@study.tech'>att@study.tech</a> or by calling us on 0204 532 2084 – Option 3 `
  },

  {
    title: 'How long is each session?',
    content:
      `Our 1-1 sessions are tailored to your schedule, lasting up to 3 hours. Your trainer may suggest taking short breaks, but please feel free to request additional breaks. Trainers are there to accommodate your needs.`
  },
  {
    title: 'How are the sessions delivered?',
    content: `These are typically delivered online, providing interactive learning from wherever you are located. We can also offer in-person training if you feel you'd benefit from face-to-face training.`,
  },
  {
    title: 'What will I learn during my session?',
    content:
      `During training sessions, your trainer will guide you on utilising your DSA-funded software and equipment. The sessions will be customised based on your individual needs and preferences, with techniques to enhance your work output, organisation, self-focus, and stress reduction. `
  },
  {
    title: `Can I request additional training once I've used up my sessions?`,
    content: `Please get in touch with us to request more training if you feel you need additional sessions. Your Funding Body will authorise any extra sessions provided there is appropriate justification.`
  },
  {
    title: `What if I must cancel my session?`,
    content: `Should you need to cancel a session, it is helpful if you can give us as much notice as possible - ideally, at least two working days. This will allow us to offer your time slot to someone else. Cancellation policies vary depending on your Funding Body, and we will provide you with this information when you book your first session.`
  }
]
  ;
const AtTrainingFaqs = () => {
  return (
    <Container>
      <Wrapper>
        <GeneralText variant='h1'>FAQs AT Training</GeneralText>
        {atTrainingFaqs.map(item => (
          <AccordionStyled key={item.title} fag={item} />
        ))}
      </Wrapper>
    </Container>
  );
};

export default AtTrainingFaqs;
