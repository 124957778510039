import React from 'react'
import { Box, Stack } from '@mui/material'
import { FormText } from '../PreAssessmentForm.styled'

const FinishStep = () => {
  return (
    <Box>
      <FormText variant='h1' sx={{ mb: 3 }}>
        Thank you for submitting the pre-assessment form.
      </FormText>
      <FormText variant='pDivider'>
        Our admin team will review it within 24 hours and contact you if we require further
        information.
      </FormText>
    </Box>
  )
}

export default FinishStep
