import React, { useState } from 'react'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import { styled } from '@mui/material/styles'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { FagsText } from './Fags.styled'

const AccordionSummaryStyled = styled(({ isAccordionStatus, ...props }) => (
  <AccordionSummary
    sx={{ padding: 0 }}
    expandIcon={
      isAccordionStatus ? (
        <RemoveCircleOutlineIcon sx={{ color: '#98A2B3', width: '24px', height: '24px' }} />
      ) : (
        <AddCircleOutlineIcon sx={{ color: '#98A2B3', width: '24px', height: '24px' }} />
      )
    }
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '@media (max-width: 750px)': {
    flexDirection: 'row'
  }
}))

const AccordionDetailsStyled = styled(AccordionDetails)(({ theme }) => ({
  marginLeft: '35px',
  '@media (max-width: 750px)': {
    marginLeft: 0,
    padding: 0
  }
}))

const AccordionStyledApp = styled(({ isAccordionStatus, ...props }) => (
  <Accordion
    disableGutters
    elevation={0}
    {...props}
    sx={{
      backgroundColor: isAccordionStatus ? '#FFF' : '#183561',
      padding: isAccordionStatus ? '32px' : '0 32px',
      borderRadius: '16px',
      marginTop: 2,
      '@media (max-width: 750px)': {
        padding: isAccordionStatus ? '20px' : '0 0'
      }
    }}
  />
))(({ theme }) => ({
  boxShadow: 'none',
  '&:before': {
    display: 'none'
  }
}))

const AccordionStyled = ({ fag }) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false)

  const handleAccordionChange = (event, isExpanded) => {
    setIsAccordionOpen(isExpanded)
  }
  return (
    <AccordionStyledApp onChange={handleAccordionChange} isAccordionStatus={isAccordionOpen}>
      <AccordionSummaryStyled isAccordionStatus={isAccordionOpen} sx={{ height: '30px' }}>
        <FagsText variant='accTitle' color={isAccordionOpen ? '#183561' : '#FFF'}>
          {fag.title}
        </FagsText>
      </AccordionSummaryStyled>
      <AccordionDetailsStyled>
        <FagsText variant='accContent' color={isAccordionOpen ? '#183561' : '#FFF'}>
          <div dangerouslySetInnerHTML={{ __html: fag.content }} />
        </FagsText>
      </AccordionDetailsStyled>
    </AccordionStyledApp>
  )
}

export default AccordionStyled
