import { Accordion, AccordionDetails, AccordionSummary, Box, Drawer, Typography } from '@mui/material';
import { styled as muiStyled } from '@mui/system';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import CustomLink from '../../Link/Link';

export const DrawerStyled = muiStyled(Drawer)(({ theme }) => ({
  display: 'none',
  '@media (max-width: 900px)': {
    display: 'block',
    '& .MuiDrawer-paper': {
      boxSizing: 'border-box',
      width: '100%',
      backgroundColor: '#224B8A',
      padding: '3px 16px'
    }
  }
}));


export const MobileMenuAppBar = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '36px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'

}));


export const LogoWrapper = styled(Box)(({ theme }) => ({
  maxWidth: '137px',
  height: '21px',
  '& img': {
    width: '100%',
    height: '21px'
  },
}));

export const CloseIconStyled = styled(CloseIcon)(({ theme }) => ({
  color: '#FFF'
}));


export const MobileMenuContent = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  marginTop: '8px'
}));

export const AccordionStyled = muiStyled(Accordion)(({ theme }) => ({
  boxShadow: 'none',
  border: 'none',
  margin: 0,
  '&::before': {
    display: 'none',
  },
}));

export const AccordionSummaryStyled = muiStyled(AccordionSummary)(({ theme }) => ({
  padding: 0,
  margin: 0,
  backgroundColor: '#224B8A',
  border: 'none',
  boxShadow: 'none',
  '& .MuiAccordionSummary-content': {
    padding: 0,
    margin: 0,
    height: '51px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },

  '& .MuiSvgIcon-root': {
    color: '#FFFFFF',
    marginRight: '8px'
  }
}));

export const AccordionDetailStyled = muiStyled(AccordionDetails)(({ theme }) => ({
  padding: 0,
  margin: 0,
  backgroundColor: '#224B8A',
  border: 'none',
  boxShadow: 'none',
}));

export const AccordionDetailsContent = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  margin: 0,
}));

export const Link = styled(CustomLink)(({ theme, fontFamily = 'Open Sans Regular', fontSize = '15px' }) => ({
  fontFamily: fontFamily,
  fontSize: fontSize,
  lineHeight: '24px',
  color: '#FFFFFF',
}));



export const Text = styled(Typography)(({ theme }) => ({
  fontFamily: 'Open Sans SemiBold',
  color: '#FFFFFF',
  '&.MuiTypography-title': {
    fontSize: '16px',
    lineHeight: '24px',
  },
}));