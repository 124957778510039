import { TextField, Box } from '@mui/material'
import { Controller } from 'react-hook-form'

export const ControlledTextField = ({
  control,
  name,
  label,
  rules,
  error,
  helperText,
  ...textFieldProps
}) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {error && <Box sx={{ alignSelf: 'flex-end', color: '#FF9E9E' }}>{helperText}</Box>}
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field: { onChange, value } }) => (
          <TextField
            name={name}
            fullWidth
            {...textFieldProps}
            label={label}
            onChange={onChange}
            value={value || ''}
            size='small'
            InputProps={{
              disableUnderline: true
            }}
            InputLabelProps={{
              sx: {
                fontFamily: 'Open Sans Regular',
                fontSize: '16px',
                paddingX: 2,
                color: value ? '#FFF' : '#6C6C99',
                fontWeight: value ? 600 : 400,
                lineHeight: '40px',
                top: value ? '-22px' : '-14px',
                '&.Mui-focused': {
                  color: '#FFF',
                  top: '-22px',
                  fontSize: '16px',
                  fontWeight: 600
                }
              }
            }}
            sx={{
              backgroundColor: '#f5f5fa',
              borderRadius: '15px',
              border: 'none',
              outline: 'none',
              paddingX: 2,
              '& .MuiInputBase-input': { fontFamily: 'Open Sans Regular' }
            }}
          />
        )}
      />
    </Box>
  )
}
