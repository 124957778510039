import React from 'react';
import {
  Content,
  ImageWrapper,
  ThirdBlockWrapper,
  ThirdContentText,
  ThirdContentText2
} from './ThirdBlock.styled';
import EachSession from '../../../../../../assets/images/home/EachSession.png';
import { GeneralText } from '../../../../../components/General/Text';
import { Text } from '../../StudentServicesAtTraining.styled';

const ThirdBlock = () => {
  return (
    <ThirdBlockWrapper>
      <Content>
        <Text variant='subtitleSemibold'>Personalised Sessions</Text>
        <ThirdContentText>
          <GeneralText variant='p'>
            Each session is customised to your needs, ensuring a bespoke experience every time.
          </GeneralText>
        </ThirdContentText>
        <Text variant='subtitleSemibold'>Flexibility</Text>
        <ThirdContentText2>
          <GeneralText variant='p'>
            We understand that life is unpredictable and plans sometimes need to change. Our team is trained to be adaptable to meet your requirements.
          </GeneralText>
        </ThirdContentText2>
        <ThirdContentText2>
          <GeneralText variant='pSemibold'>
            Through this flexible approach, we will help you achieve your academic goals whilst maintaining a healthy work-life balance.
          </GeneralText>
        </ThirdContentText2>
      </Content>
      <ImageWrapper>
        <img src={EachSession} alt='Illustration of two people having a conversation, with speech bubbles, shown in yellow frames against a navy background.' />
      </ImageWrapper>
    </ThirdBlockWrapper>
  );
};

export default ThirdBlock;
