import React from 'react';
import { Card, CardContent, CardImage, CardLink, CardText, CardWrapper } from './MainCards.styled';
import needsAssessment from '../../../../assets/images/home/needsAssessment.svg';
import training from '../../../../assets/images/home/training.svg';
import equpment from '../../../../assets/images/home/equpment.svg';
import ergonomicAssessment from '../../../../assets/images/home/ergonomic-assessment.svg';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';



const mainCards = [
  {
    title: 'Needs Assessments',
    content: 'Individual DSA needs assessment: Learning support designed for you.',
    label: 'Read More',
    path: '/student-services-assessments',
    image: needsAssessment,
    imageLabel: 'A person seated at a table, writing on a sheet of paper with a pencil. They are wearing a dark green sweater, and there is a book placed on the table nearby. The background is blurred, suggesting an indoor setting.'
  },
  {
    title: 'Equipment and Software',
    content: 'Ready to order your DSA equipment or upgrade your current setup?',
    label: 'Read More',
    path: '/student-services-equipment-software',
    image: equpment,
    imageLabel: 'A modern desktop workstation setup featuring a widescreen monitor displaying a colourful wallpaper with blue, purple and green waves. The desk includes a wireless keyboard, mouse, and what appears to be a second monitor partially visible on the left. The setup sits on a white desk surface.'
  },
  {
    title: 'AT Training',
    content:
      'Our personalised assistive technology training transforms your working methods, maximising your time and efficiency.',
    label: 'Read More',
    path: '/student-services-at-training',
    image: training,
    imageLabel: 'A male assistive technology trainer in a white shirt and glasses sits alongside a female student with curly hair and glasses wearing a grey jumper looking at a laptop screen together. In the background, wooden shelving with files and plants decorates the wall.'
  },
  {
    title: 'Ergonomic Assessment',
    content:
      'Ergonomic Assessment to optimise your workspace and working practices.',
    label: 'Read More',
    path: '/student-services-ergonomic-assessment',
    image: ergonomicAssessment,
    imageLabel: 'Side view of someone working at an ergonomic setup, showing a person in a grey sweater sitting in a white mesh chair with adjustable armrests. Their hands are positioned on a keyboard at a white desk. Plants and natural lighting are visible in the background window area.'
  }
];

const MainCards = () => {
  return (
    <CardWrapper>
      {mainCards.map(card => (
        <Card key={card.title}>
          <CardContent>
            <CardText variant='h2'>{card.title}</CardText>
            <CardText variant='p'>{card.content}</CardText>
            <CardLink to={card.path} label={card.title} >
              {card.label}<ArrowRightAltIcon  />
            </CardLink>
          </CardContent>

          <CardImage>
            <img src={card.image} alt={card.imageLabel} />
          </CardImage>
        </Card>
      ))}
    </CardWrapper>
  );
};

export default MainCards;
