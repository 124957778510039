import React from 'react'
import { Box, Stack } from '@mui/material'
import { FormText } from '../PreAssessmentForm.styled'
import { ControlledTextField } from '../../../components/inputs/ControlledTextField'
import { ControlledAutocomplete } from '../../../components/inputs/ControlledAutocomplete'
import { ControlledDatePicker } from '../../../components/inputs/ControlledDatePicker'
import { yearOfStudyOptions } from '../../../../app/constants/const'
import { ControlledCheckbox } from '../../../components/inputs/ControlledCheckbox'
import { useCurrentScreenWidth } from '../../../../hooks/screenSize/useCurrentSceenWidth'

const levelOfStudyOptions = [
  { label: 'Undergraduate', value: 'Undergraduate' },
  { label: 'Postgraduate', value: 'Postgraduate' }
]

const modeOfStudyOptions = [
  { label: 'Full Time', value: 'Full Time' },
  { label: 'Part Time', value: 'Part Time' },
  { label: 'Distance Learning - Full Time', value: 'Distance Learning - Full Time' },
  { label: 'Distance Learning - Part Time', value: 'Distance Learning - Part Time' }
]

const ContactDetailStep = ({ control, errors }) => {
  const [screenWidth] = useCurrentScreenWidth()
  return (
    <Box>
      <FormText variant='h1'>Contact Details and Course Information</FormText>
      <Stack direction={'column'} spacing={3} sx={{ mt: screenWidth >= 750 ? '40px' : '20px' }}>
        <ControlledTextField
          variant='standard'
          control={control}
          name='nameSurname'
          label='Name and Surname*'
          rules={{ required: true, minLength: 3 }}
          error={!!errors.nameSurname}
          helperText='Name and Surname is required and should have a minimum length of 3'
        />
        <ControlledTextField
          variant='standard'
          control={control}
          name='email'
          label='Email*'
          rules={{
            required: true,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
            }
          }}
          error={!!errors.email}
          helperText='Invalid email address'
        />
        <ControlledTextField
          control={control}
          name='phone'
          label='Telephone Number*'
          type='number'
          variant='standard'
          rules={{
            required: true,
            pattern: {
              value: /((\+44(\s\(0\)\s|\s0\s|\s)?)|0)[0-9]\d{3}(\s)?\d{6}/g
            }
          }}
          error={!!errors.phone}
          helperText='Invalid phone number format. Correct format : 07123 456789'
        />
        <ControlledTextField
          variant='standard'
          control={control}
          name='university'
          label='University*'
          rules={{ required: true, minLength: 3 }}
          error={!!errors.university}
          helperText='University is required and should have a minimum length of 3'
        />
        <ControlledTextField
          variant='standard'
          control={control}
          name='courseName'
          label='Full Course Name (as on University Website)*'
          rules={{ required: true, minLength: 3 }}
          error={!!errors.courseName}
          helperText='Full Course Name (as on University Website) is required and should have a minimum length of 3'
        />
        <ControlledDatePicker
          variant='standard'
          control={control}
          name='courseStartDate'
          label='Course Start date*'
          rules={{ required: true, minLength: 3 }}
          error={!!errors.courseStartDate}
          helperText='Course Start date is required and should have a minimum length of 3'
        />
        <ControlledAutocomplete
          control={control}
          name='courseDuration'
          label='Course Duration*'
          options={yearOfStudyOptions}
          rules={{ required: true }}
          error={!!errors.university}
          helperText='Course Duration is required'
        />
        <ControlledAutocomplete
          control={control}
          name='levelStudy'
          label='Level of Study*'
          options={levelOfStudyOptions}
          rules={{ required: true }}
          error={!!errors.levelStudy}
          helperText='Level of Study is required'
        />
        <ControlledAutocomplete
          control={control}
          name='modeStudy'
          label='Mode of study*'
          options={modeOfStudyOptions}
          rules={{ required: true }}
          error={!!errors.modeStudy}
          helperText='Mode of study is required'
        />
        <ControlledCheckbox
          control={control}
          name='agree'
          label='I am happy for another Assessor to observe my Needs Assessment Appointment for training or quality purposes.'
        />
      </Stack>
    </Box>
  )
}

export default ContactDetailStep
