import React from 'react';
import { Box, styled } from '@mui/system';

export const BoxStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  margin: '180px 0 0 0',
  padding: '0 24px',
  boxSizing: 'border-box',
  '@media (max-width: 750px)': {
    marginTop: '40px'
  }
}));

const ContainerStyled = ({ children }) => {
  return (
    <BoxStyled>
      {children}
    </BoxStyled>
  );
};

export default ContainerStyled;
