import { Box,  Divider, Typography } from '@mui/material';
import styled from 'styled-components';
import {styled as styledMui } from '@mui/system';
import CustomLink from '../Link/Link';


export const Wr = styled('footer')(({ theme }) => ({
  maxWidth: '100%',
  width: '100%',
  backgroundColor: '#183561',
}));


export const Wrapper = styled(Box)(({ theme }) => ({
  maxWidth: '1440px',
  width: '100%',
  margin: '0 auto 0 auto',
  height: '100%',
  padding: '0 16px 19px 16px',
  boxSizing: 'border-box',
  
}));

export const FooterContainerStyled = styledMui(Box)({
  maxWidth: '1312px',
  width: '100%',
  height: '100%',
  margin: '43px auto 19px auto',
  '@media (max-width: 900px)': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
});
export const FooterContent = styled(Box)(({ theme }) => ({
  width: '100%',
  marginTop: '64px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  '@media (max-width: 900px)': {
    display: 'none'
  }
}));

export const FooterDown = styled(Box)(({ theme }) => ({
  maxWidth: '1312px',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '58px auto 19px auto',
  height: '37px',
  '@media (max-width: 900px)': {
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
  }
}));

export const FooterDownLeftSide = styled(Box)(({ theme }) => ({
  maxWidth: '530px',
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '@media (max-width: 900px)': {
    flexDirection: 'column-reverse',
    alignItems: 'center',
    marginBottom:'26px'
  }
}));

export const Certificates = styled(Box)(({ theme }) => ({
  maxWidth: '356px',
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '@media (max-width: 900px)': {
    flexDirection: 'column',
    alignItems: 'center',
    '& img': {
      marginTop: '6px'
    }
  }
}));

export const CertificateImg = styled(Box)(({ theme }) => ({
  maxWidth: '356px',
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '@media (max-width: 900px)': {
    flexDirection: 'column',
    alignItems: 'center'
  }
}));

export const DividerStyled = styledMui(Divider)({
  maxWidth: '1312px',
  width: '100%',
  margin: '0 auto',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
});

export const DividerStyledMobile = styledMui(Divider)({
  maxWidth: '164px',
  width: '100%',
  margin: '0 auto',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
  display: 'none',
  marginBottom: '10px',
  '@media (max-width: 900px)': {
    display: 'block'
  }
  ,
});

export const FooterLogo = styled(Box)(({ theme }) => ({
  maxWidth: '164px',
  width: '164px',
  height: '24px',
}));



export const Link = styled(CustomLink)(({ theme, underline }) => ({
  color: '#FFFFFF',
  fontFamily: `Open Sans Regular`,
  fontSize: '16px',
  lineHeight: '24px',
  textDecoration: underline,
  '@media (max-width: 750px)': {
    fontSize: '16px',
  }
}));



export const Text = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  color: '#FFFFFF',
  fontFamily: 'Open Sans Bold',
  '&.MuiTypography-subtitle': {
    fontSize: '16px',
    lineHeight: '24px',
  },
  '&.MuiTypography-p': {
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'Open Sans Regular',
    color: '#98A2B3'
  },
  '@media (max-width: 900px)': {
    marginBottom: '10px'
  }
}));
