import { Box } from "@mui/material";
import React from "react";
import { ContentWrapper, Text } from "../StudentServicesAtTraining.styled";
import FirstBlock from "./first-block/FirstBlock";
import SecondBlock from "./second-block/SecondBlock";
import ThirdBlock from "./third-block/ThirdBlock";

const Blocks = () => {
  return (
    <Box>
      <ContentWrapper>
        <Text variant='subtitle'>We are committed to providing personalised learning experiences that match your needs. Our focus is to help you maximise the benefits of your Assistive Technology.</Text>
      </ContentWrapper>
      <FirstBlock />
      <SecondBlock />
      <ThirdBlock/>
    </Box>
  );
};

export default Blocks;
