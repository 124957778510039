import React from 'react'
import { Autocomplete, ListItem, TextField, Box } from '@mui/material'
import { Controller } from 'react-hook-form'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  option: {
    cursor: 'pointer',
    color: '#183561',
    fontFamily: 'Inter regular',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    '&:hover': {
      backgroundColor: '#FCF0B2;'
    }
  }
})

export const ControlledAutocomplete = ({
  control,
  name,
  label,
  options,
  required,
  isLoading = false,
  disableClearable = false,
  noOptionsText,
  rules,
  error,
  helperText,
  disabled = false
}) => {
  const classes = useStyles()

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {error && <Box sx={{ alignSelf: 'flex-end', color: '#FF9E9E' }}>{helperText}</Box>}
      <Controller
        control={control}
        rules={rules}
        name={name}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            name={name}
            sx={{
              background: '#f5f5fa',
              borderRadius: '15px',
              paddingX: 2,
              '& .MuiAutocomplete-endAdornment': {
                top: '-4px'
              },
              '& .MuiFormLabel-root': {
                fontFamily: 'Open Sans Regular',
                color: value ? '#FFF' : '#6C6C99',
                top: value ? '-16px' : '-4px',
                fontSize: '16px',
                fontWeight: value ? 600 : 400,
                '&.Mui-focused': {
                  color: '#FFF',
                  top: '-16px',
                  fontSize: '16px',
                  fontWeight: 600
                }
              }
            }}
            fullWidth
            value={options.find(option => option.value === value) ?? null}
            onChange={(_, newValue) => onChange(newValue?.value ?? null)}
            options={options}
            renderInput={params => (
              <TextField
                name={name}
                variant='standard'
                size='small'
                {...params}
                required={required}
                label={label}
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true
                }}
              />
            )}
            renderOption={(props, option) => (
              <ListItem {...props} key={option.value} className={classes.option}>
                {option.label}
              </ListItem>
            )}
            loading={isLoading}
            disableClearable={disableClearable}
            noOptionsText={noOptionsText}
            size='small'
            disabled={disabled}
          />
        )}
      />
    </Box>
  )
}
