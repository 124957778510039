import { Box } from '@mui/material'
import styled from '@emotion/styled'

export const SecondBlockWrapper = styled(Box)(({ theme }) => ({
  maxWidth: '993px',
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxSizing: 'border-box',
  marginTop: '120px',
  '@media (max-width: 750px)': {
    marginTop: '40px',
    flexDirection: 'column-reverse',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%'
  }
}))

export const Content = styled(Box)(({ theme }) => ({
  maxWidth: '433px',
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  '@media (max-width: 750px)': {
    height: '100%'
  }
}))

export const ImageWrapper = styled(Box)(({ theme }) => ({
  maxWidth: '415px',
  width: '100%',
  height: '415px',
  '& img': {
    width: '100%'
  },
  '@media (max-width: 750px)': {
    height: '293px',
    display: 'flex',
    justifyContent: 'center'
  },
  '@media (max-width: 235px)': {
    display: 'none'
  }
}))

export const SecondContentText = styled(Box)(({ theme }) => ({
  marginBottom: '24px',
  '@media (max-width: 750px)': {
    marginBottom: '16px'
  }
}))
