import React from 'react';
import { Box, } from '@mui/material';
import { Card, CardContent, CardImage, ContentWrapper, Text } from './EquipmentSoftware.styled';
import placeOrder from '../../../../assets/images/student-services/equipment/placeOrder.svg';
import delivery from '../../../../assets/images/student-services/equipment/delivery.svg';
import star from '../../../../assets/images/student-services/equipment/star.svg';

const Equipment = () => {
  return (
    <Box>
      <ContentWrapper>
        <Text variant='p'>We provide a comprehensive range of assistive technologies to meet your individual requirements. Approximately 50% of our customers choose to <Text variant='pBold'>upgrade</Text> from our standard recommended laptop. We will send you various options, and our friendly team is here to discuss different computer specifications.</Text>
      </ContentWrapper>
      <ContentWrapper>
        <Text variant='pBold'>Please note that computer upgrades are only possible before we dispatch your order. Changes cannot be made later in the DSA process.</Text>
      </ContentWrapper>

      <ContentWrapper>
        <Card>
          <CardImage>
            <img src={placeOrder} alt='Place Your Order (document icon) with contact details' />
          </CardImage>
          <CardContent >
            <Text variant='title'>
              1. Place Your Order
            </Text>
            <Text variant='pSemiBold'>
              Email orders@study.tech or call us on 0204 532 2084 – Option 2.
            </Text>
          </CardContent>
        </Card>

        <Card>
          <CardImage>
            <img src={delivery} alt='Delivery (box icon) with shipping information' />
          </CardImage>
          <CardContent >
            <Text variant='title'>
              2. Delivery
            </Text>
            <Text variant='pSemiBold'>
              We will deliver your order within the specified time frame in your delivery confirmation. For bespoke upgrades, please note that waiting times may be longer if manufacturers are building your computer to order.
            </Text>
          </CardContent>
        </Card>

        <Card>
          <CardImage>
            <img src={star} alt='Feedback (star icon) with customer service details' />
          </CardImage>
          <CardContent >
            <Text variant='title'>
              3. Feedback
            </Text>
            <Text variant='pSemiBold'>
              We value your input. We will contact you to gather feedback, helping us to continuously improve our services. Thank you for taking the time to respond.
            </Text>
          </CardContent>
        </Card>
      </ContentWrapper>
    </Box>
  );
};

export default Equipment;
