import React from 'react'
import { Radio, RadioGroup, FormControlLabel, FormControl, Box, Stack } from '@mui/material'
import { Controller } from 'react-hook-form'
import { makeStyles } from '@mui/styles'
import { FormText } from '../PreAssessmentForm.styled'

const useStyles = makeStyles({
  radioLabel: {
    fontFamily: 'Inter regular',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    color: '#FFF'
  },
  formControl: {
    marginTop: '16px',
    display: 'flex',
    flexDirection: 'row',
    '& .MuiRadio-root': {
      color: '#FFF',
      '&.Mui-checked': {
        color: '#FFF'
      }
    },
    '& .MuiIconButton-root': {
      color: '#FFF'
    }
  }
})

export const ControlledHorizontalRadioButton = ({
  control,
  name,
  label,
  options,
  required,
  error,
  helperText,
  rules,
  disabled = false,
  defaultValue,
  title
}) => {
  const classes = useStyles()

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {error && <Box sx={{ alignSelf: 'flex-end', color: '#FF9E9E' }}>{helperText}</Box>}
      <Controller
        control={control}
        rules={rules}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { onChange, value } }) => (
          <FormControl component='fieldset' className={classes.formControl}>
            <RadioGroup row name={name} value={value} onChange={e => onChange(e.target.value)}>
              <FormText variant='pDivider'>{title}</FormText>
              <Stack direction={'row'} spacing={1} alignItems={'center'} alignContent={'center'}>
                <FormText variant='pDivider'>Poor</FormText>
                {options.map(option => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={<Radio />}
                    label={option.label}
                    classes={{ label: classes.radioLabel }}
                    disabled={disabled}
                  />
                ))}
                <FormText variant='pDivider'>Excellent</FormText>
              </Stack>
            </RadioGroup>
          </FormControl>
        )}
      />
    </Box>
  )
}
