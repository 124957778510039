import React from 'react';
import {
  Content,
  FirstBlockWrapper,
  FirstContentText,
  FirstContentText2,
  ImageWrapper
} from './FirstBlock.styled';
import SaveTime from '../../../../../../assets/images/home/SaveTime.png';
import { GeneralText } from '../../../../../components/General/Text';
import { Text } from '../../StudentServicesAtTraining.styled';

const FirstBlock = () => {
  return (
    <FirstBlockWrapper>
      <Content>
        <Text variant='subtitleSemibold' >Enhance your time management, productivity and academic performance.</Text>
        <FirstContentText>
          <GeneralText variant='p' >
            Academic commitments can create huge demands on our time and well-being. Ensuring you remain focused and efficient is essential to meeting challenges. Our experienced team is here to support you.
          </GeneralText>
        </FirstContentText>
        <FirstContentText2>
          <GeneralText variant='p' sx={{ mb: 6 }}>
            AT training will help you to enhance your time management, productivity, and academic
            performance.
          </GeneralText>
        </FirstContentText2>
      </Content>
      <ImageWrapper>
        <img src={SaveTime} alt=' A stylised digital illustration of a person in a yellow jumper sat with a laptop beside an oversized hourglass filled with yellow liquid, set against a navy background.' />
      </ImageWrapper>
    </FirstBlockWrapper>
  );
};

export default FirstBlock;
