import React from 'react';
import Container from '../../../components/General/Container';
import { ContentWrapper, ImgWrapper, Wrapper } from './About.styled';
import { GeneralText } from '../../../components/General/Text';
import AboutUs from '../../../../assets/images/about/about.png';


const About = () => {
  return (
    <Container>
      <Wrapper>
        <GeneralText variant='h1'>About us</GeneralText>
        <GeneralText variant='p'>
          At Study Tech, we are committed to enhancing the academic performance of students who have
          received the DSA award. We provide bespoke tools to support studies and reduce stress in
          both work and life. Our objective is to ensure that every individual has access to the
          resources they need to achieve their full potential. We believe that with the right tools
          and guidance, everyone will excel in their academic pursuits and transition to the next
          phase of their lives.
        </GeneralText>
        <ContentWrapper>
          <GeneralText variant='p'>
            An innovative team of assistive technology service providers, we have helped hundreds of
            thousands of disabled customers across the UK reach their education potential. We are
            committed to working with Student Finance to modernise DSA and deliver enhanced services
            to individuals.
          </GeneralText>
        </ContentWrapper>
        <ImgWrapper>
          <img src={AboutUs} alt='Three people high-fiving, with a desk chair and computer screen in the background.' />
        </ImgWrapper>
        <ContentWrapper>
          <GeneralText variant='p'>
            <b>We all learn in different ways.</b> When at School, College, or University you often have to conform to a set way of teaching. Large institutional organisations can be limited in their ability to personalise education. Study Tech’s role is to focus on the individual and customise an individual's work and study. Our assessors and trainers facilitate the exploration of student-centred ways to produce work. With a range of over 500 products and 1-1 support to choose from, every assessment and equipment order is unique.
          </GeneralText>
        </ContentWrapper>
        <ContentWrapper>
          <GeneralText variant='p'>
            <b>An inclusive philosophy.</b> A recognition of individual diversity is crucial to building on
            people's strengths. Forward-thinking 1-1 assessments are about you: a dialogue to
            enhance future learning. AT training starts with individual learning plans and an
            appreciation of your uniqueness. Customers drive 1-1 sessions in a direction that suits
            them, with suggestions from trainers on accelerating grades, saving time, and reducing
            pressure.
          </GeneralText>
        </ContentWrapper>
        <ContentWrapper>
          <GeneralText variant='p'>
            <b>Our mission</b> is to personalise learning, to facilitate reasonable adjustments and new
            ways of studying. Working with our partners in Universities, the Student Loans Company,
            the Welsh Government, SAAS, and the Department for Education, we are committed to
            delivering measurable value to individuals.
          </GeneralText>
        </ContentWrapper>
      </Wrapper>
    </Container>
  );
};

export default About;
