import { Box } from '@mui/material'
import styled from 'styled-components'

export const Wrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  marginBottom: '120px',
  '@media (max-width: 750px)': {
    marginBottom: '56px'
  },
  '& ul li::marker': {
    color: 'white'
  }
}))

export const ContentWrapper = styled(Box)(({ theme }) => ({
  marginTop: '42px',
  '@media (max-width: 750px)': {
    marginTop: '18px'
  }
}))



export const MrWrapper = styled(Box)(({ theme }) => ({
  margin: '16px 0',
  '@media (max-width: 750px)': {
    marginTop: '8px'
  }
}))

