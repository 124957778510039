import React from "react";
import Container from "../../../components/General/Container";
import { Wrapper } from "../Fags.styled";
import { GeneralText } from "../../../components/General/Text";
import AccordionStyled from "../Accordion";

const ergonomicFags = [
  {
    title: 'What is an ergonomic assessment?',
    content: `Ergonomics is the process of organising and setting up a workstation that is most suitable for the user’s needs. In an ergonomic assessment, we will look at adapting current equipment and adopting new equipment and working techniques for maximum benefit when sitting or standing at the workstation.`
  },
  {
    title: 'What happens during an ergonomic assessment?',
    content: `We will assess your workstation and working habits in relation to your overall needs. We may ask questions about how your symptoms are affected by certain working habits and then make recommendations based on the assessment. We may need to take measurements from you for specialist equipment.`
  },
  {
    title: 'How do I book my assessment?',
    content: `You can book your ergonomic assessment by emailing <a href='mailto:ergo@study.tech' class='email-link-dark' aria-label='ergo@study.tech'>ergo@study.tech</a> or by telephone on 0204 532 2084 - Option 5.`
  },
  {
    title: 'Where will the assessment take place?',
    content: `The assessment takes place at your term-time address. Access to area(s) of study is preferable so that space requirements can be assessed.`
  },
  {
    title: 'How long will the assessment take?',
    content: `The assessment usually takes around 45 minutes to one hour.`
  },
  {
    title: 'Can I cancel my assessment?',
    content: `We ask that assessments are not cancelled less than 24 hours in advance due to the travel our assessors must undertake. If you need to reschedule the assessment you can email <a href='mailto:ergo@study.tech' class='email-link-dark' aria-label='ergo@study.tech'>ergo@study.tech</a> or by telephone on 0204 532 2084 - Option 5.`
  },
  {
    title: 'Do I need to contribute money for the equipment?',
    content: `No. The funding body fully covers ergonomic equipment, and you will not be expected to make any contributions.`
  },
  {
    title: 'How do I order my ergonomic equipment?',
    content: `Your funding body will notify us when your equipment recommendations are approved. Our orders team will then place your order and notify you when it is ready for delivery. If you do not hear from us within four weeks of the assessment, please email <a href='mailto:orders@study.tech' class='email-link-dark' aria-label='orders@study.tech'>orders@study.tech</a>`
  },
  {
    title: 'How long will my order take?',
    content: `We will contact you within ten days of your equipment being approved by the funding body for delivery of small items. Larger items, which are more likely to be customised for your needs, can take up to four weeks from the point of approval.`
  },
  {
    title: 'How will my equipment be delivered?',
    content: `Your equipment order will be delivered in two parts: large items requiring installation and small items not requiring installation. You will be notified when each part of your order is ready for delivery.`
  },
  {
    title: 'How long can I keep the ergonomic equipment?',
    content: `The equipment is yours to keep for your course and after that. The equipment is not expected to be returned to us at the end of the study period. `
  },
  {
    title: 'What happens if my equipment breaks?',
    content: `We will investigate the nature of the breakage with you and determine if this can be covered by warranty for replacement. Where this cannot be covered, we may request additional funding from the funding body for replacement (subject to their approval).`
  },
  {
    title: 'Who do I contact with equipment queries?',
    content: `You can email <a href='mailto:ergo@study.tech' class='email-link-dark'>ergo@study.tech</a> or by telephone on 0204 532 2084 - Option 5.`
  },

];

const ErgonomicFags = () => {
  return (
    <Container>
      <Wrapper>
        <GeneralText variant='h1'>FAQs Ergonomics</GeneralText>
        {ergonomicFags.map(item => (
          <AccordionStyled key={item.title} fag={item} />
        ))}
      </Wrapper>
    </Container>
  );
};

export default ErgonomicFags;
