import { Box, Container } from '@mui/material';
import React from 'react';
import { MrWrapper, Wrapper } from './RefundPolicy.styled';
import { GeneralText } from '../../components/General/Text';


const RefundPolicy = () => {
  return (
    <Container>
      <Wrapper>
        <GeneralText variant='h1'>Refund Policy for DSA Students</GeneralText>
        <GeneralText variant='h2'>Introduction</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>Whilst we aim to get your equipment right the first time, there may be instances where you need to request a refund from us. This policy applies only to equipment supplied to you by Study Tech.</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>Our refund policy is designed to offer a fast and fair service. Should you have a dispute with a refund request you maintain the right to raise this through your payment card issuer, however that will suspend the process set out below and we will then work directly with your card issuer following their dispute process. This may result in a longer resolution time.</GeneralText>
        </MrWrapper>
        <GeneralText variant='pBold'>1. Conditions for Refunds</GeneralText>
        <MrWrapper>
          <ul>
            <li><GeneralText variant='p'>Refunds will be issued if equipment is returned within 30 calendar days of delivery.</GeneralText></li>
            <li><GeneralText variant='p'>The equipment must be in its original condition, including all accessories, packaging, and documentation.</GeneralText></li>
            <li><GeneralText variant='p'>Refunds will not be issued for equipment that has been damaged, altered, or shows signs of use.</GeneralText></li>
            <li><GeneralText variant='p'>You must ensure the packaging to return the equipment is done to a high standard to avoid any damage in transit. We recommend you take a photo of the packaging so that if there is a dispute later we have the necessary evidence for us to claim from DPD.</GeneralText></li>
            <li><GeneralText variant='p'>Equipment in opened original packaging will incur a restocking fee.</GeneralText></li>
          </ul>
        </MrWrapper>
        <GeneralText variant='pBold'>2. Refund Process</GeneralText>
        <MrWrapper>
          <ul>
            <li><GeneralText variant='p'>To initiate a refund, you must contact our Orders team within 30 calendar days of receiving the equipment.</GeneralText></li>
            <li><GeneralText variant='p'>The Orders team will process the request and if valid will arrange collection of the device with the customer.</GeneralText></li>
            <li><GeneralText variant='p'>Once the laptop is received it will be inspected as per the conditions outlined in Section 1. of this policy.</GeneralText></li>
            <li><GeneralText variant='p'>If the device passes the inspection the refund will be processed within 10 business days via the original payment method.</GeneralText></li>
            <li><GeneralText variant='p'>The customer will be notified of the outcome.</GeneralText></li>
          </ul>
        </MrWrapper>
        <GeneralText variant='pBold'>3. Refund Amount</GeneralText>
        <MrWrapper>
          <ul>
            <li><GeneralText variant='p'>The refund amount will be the price you paid for the equipment, minus any applicable restocking fees.</GeneralText></li>
            <li><GeneralText variant='p'>You will not be refunded any associated shipping costs incurred at the time of order.</GeneralText></li>
            <li><GeneralText variant='p'>Restocking fee will be £80.00 for all unopened computer equipment and 10% of the cost for all other items.</GeneralText></li>
            <li><GeneralText variant='p'>The £200 DSA contribution will be refunded only if laptop/ computer equipment is returned.</GeneralText></li>
          </ul>
        </MrWrapper>
        <GeneralText variant='pBold'>4. Exceptions</GeneralText>
        <MrWrapper>
          <ul>
            <li><GeneralText variant='p'>Refunds will not be issued for software or digital products that were included with the equipment.</GeneralText></li>
            <li><GeneralText variant='p'>Custom-upgrade laptops are not eligible for refund. These laptops have been either ordered specifically for you, and do not appear on the standard Upgrades list.</GeneralText></li>
            <li><GeneralText variant='p'>Opened in-ear headsets are not eligible for refunds. </GeneralText></li>
          </ul>
        </MrWrapper>
        <GeneralText variant='pBold'>5. Contact Information</GeneralText>
        <MrWrapper>
          <ul>
            <li><GeneralText variant='p'>For any questions please contact our Orders team at <a href='mailto:orders@study.tech' className='email-link' aria-label='orders@study.tech'>orders@study.tech</a> or 020 4532 2084 Option 2.</GeneralText></li>
          </ul>
        </MrWrapper>
      </Wrapper>
    </Container>
  );
};

export default RefundPolicy;
