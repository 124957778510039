import React from "react";
import Container from "../../components/General/Container";
import { Block, Link, SitemapDivider, Text, Wrapper } from "./Sitemap.styled";
import { GeneralText } from "../../components/General/Text";
import { Grid } from '@mui/material';

const studentServices = [
  { name: 'Needs Assessments', path: '/student-services-assessments' },
  { name: 'AT Training', path: '/student-services-at-training' },
  { name: 'Ergonomic Assessments', path: '/student-services-ergonomic-assessment' },
  { name: 'Equipment and Software', path: '/student-services-equipment-software' }
];

const dsaInformation = [
  { name: 'DSA Information', path: '/dsa-information' },

];

const faqs = [
  { name: 'Needs Assessments', path: '/frequently-asked-questions-assessments' },
  { name: 'AT Training', path: '/frequently-asked-questions-at-training' },
  { name: 'Ergonomic Assessments', path: '/frequently-asked-questions-ergonomics' },
  { name: 'Equipment and Software', path: '/frequently-asked-questions-equipment' }
];

const legal = [
  { name: 'Privacy Policy', path: '/privacy-policy' },
  { name: 'Cookie Policy', path: '/cookie-policy' },
  { name: 'Complaints Policy', path: '/complaints-policy' },
  { name: 'Accessibility', path: '/accessibility' },

  { name: 'Modern Slavery Act', path: '/modern-slavery-act' },
  { name: 'Term and Conditions', path: '/terms-and-conditions' },
  { name: 'Refund Policy', path: '/refund-policy' }
];

const company = [
  { name: 'About us', path: '/about' },
  { name: 'Careers', path: '/careers' },
];

const contact = [
  { name: 'Contact Us', path: '/contact' },
];

const Sitemap = () => {
  return (
    <Container>
      <Wrapper>
        <GeneralText variant='h1'>Sitemap</GeneralText>
        <Text variant="subtitle">Student Services</Text>
        <SitemapDivider />
        <Block>
          <Grid container spacing={2}>
            {studentServices.map(({ name, path }) => (
              <Grid item xs={12} sm={6} md={6} key={path}>
                <Link to={path}>
                  {name}
                </Link>
              </Grid>
            ))}
          </Grid>
        </Block>

        <Text variant="subtitle">DSA Information</Text>
        <SitemapDivider />
        <Block>
          <Grid container spacing={2}>
            {dsaInformation.map(({ name, path }) => (
              <Grid item xs={12} sm={6} md={6} key={name}>
                <Link to={path}>
                  {name}
                </Link>
              </Grid>
            ))}
          </Grid>
        </Block>

        <Text variant="subtitle">FAQs</Text>
        <SitemapDivider />
        <Block>
          <Grid container spacing={2}>
            {faqs.map(({ name, path }) => (
              <Grid item xs={12} sm={6} md={6} key={name}>
                <Link to={path}>
                  {name}
                </Link>
              </Grid>
            ))}
          </Grid>
        </Block>

        <Text variant="subtitle">Legal</Text>
        <SitemapDivider />
        <Block>
          <Grid container spacing={2}>
            {legal.map(({ name, path }) => (
              <Grid item xs={12} sm={6} md={4} key={name}>
                <Link to={path}>
                  {name}
                </Link>
              </Grid>
            ))}
          </Grid>
        </Block>

        <Text variant="subtitle">Company</Text>
        <SitemapDivider />
        <Block>
          <Grid container spacing={2}>
            {company.map(({ name, path }) => (
              <Grid item xs={12} sm={6} md={6} key={name}>
                <Link to={path}>
                  {name}
                </Link>
              </Grid>
            ))}
          </Grid>
        </Block>


        <Text variant="subtitle">Contact Us</Text>
        <SitemapDivider />
        <Block>
          <Grid container spacing={2}>
            {contact.map(({ name, path }) => (
              <Grid item xs={12} sm={6} md={6} key={name}>
                <Link to={path}>
                  {name}
                </Link>
              </Grid>
            ))}
          </Grid>
        </Block>
      </Wrapper>
    </Container>
  );
};

export default Sitemap;
