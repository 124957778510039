import { Box, Typography } from '@mui/material';
import styled from 'styled-components';
import Divider from '@mui/material/Divider';
import CustomLink from '../../components/Link/Link';


export const Wrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  marginBottom: '77px',
  '@media (max-width: 750px)': {
    marginBottom: '56px'
  }
}));

export const Block = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: '#3D65A2',
  borderRadius: '8px',
  padding: '33px 27px',
  marginTop: '18px',
  marginBottom: '40px',
  '@media (max-width: 750px)': {
    padding: '17px 27px',
    marginTop: '18px',
    marginBottom: '32px',
  }
}));

export const SitemapDivider = styled(Divider)(({ theme }) => ({
  width: '100%',
  marginTop: '8px',
  backgroundColor: '#FFFFFF',
  '@media (max-width: 750px)': {
    display: 'none'

  }

}));

export const Link = styled(CustomLink)(({ theme }) => ({
  color: '#FFFFFF',
  fontFamily: 'Open Sans Regular',
  fontSize: '19px',
  lineHeight: '28px',
  textDecoration: 'underline',
  '@media (max-width: 750px)': {
    fontSize: '16px',
    lineHeight: 'auto',
  }
}));




export const Text = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  color: '#FFFFFF',
  '&.MuiTypography-subtitle': {
    fontFamily: 'Open Sans Bold',
    fontSize: '41px',
    lineHeight: '62px',
  },
  ///
  '@media (max-width: 750px)': {
    '&.MuiTypography-subtitle': {
      fontSize: '18px',
      lineHeight: 'auto',
    },
  }
}));
