import { Box, Typography } from '@mui/material';
import styled from 'styled-components';

export const Wrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  marginBottom: '120px',
  '@media (max-width: 750px)': {
    marginBottom: '56px'
  },
  '& ul li::marker': {
    color: 'white'
  }
}));

export const ContentWrapper = styled(Box)(({ theme }) => ({
  marginTop: '42px',
  '@media (max-width: 750px)': {
    marginTop: '18px'
  }
}));

export const DsaStudentContentWrapper = styled(Box)(({ theme }) => ({
  marginTop: '80px',
  marginBottom: '40px',
  '@media (max-width: 750px)': {
    marginTop: '32px',
    marginBottom: '40px'
  }
}));


export const ImgWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '864px',
  marginBottom: '80px',
  '& img': {
    width: '100%'
  },
  '@media (max-width: 750px)': {
    marginTop:'32px',
    marginBottom: '40px'
  }
}));


export const Card = styled(Box)(({ theme }) => ({
  boxSizing: 'border-box',
  width: '100%',
  backgroundColor: '#FFFFFF',
  minHeight: '190px',
  borderRadius: '30px',
  padding: '21px 25px 21px 17px',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  marginTop: '32px',
  '@media (max-width: 750px)': {
    padding: '16px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: '24px',
  }
}));

export const CardContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  marginLeft: '46px',
  '@media (max-width: 750px)': {
    marginLeft: 0
  }
}));


export const CardImage = styled(Box)(({ theme }) => ({
  maxWidth: '148px',
  height: '100%',
  '& img': {
    '@media (max-width: 750px)': {
      width: '100%',
      maxWidth: '58px',
      height: '58px'
    },

  }
}));

export const Text = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  fontFamily: 'Open Sans Regular',
  color: '#FFFFFF',
  '&.MuiTypography-title': {
    fontFamily: 'Open Sans Bold',
    fontSize: '32px',
    lineHeight: '35px',
    color: '#183561'
  },
  '&.MuiTypography-p': {
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '30px'
  },
  '&.MuiTypography-pBold': {
    fontFamily: 'Open Sans Bold',
    fontSize: '18px',
    lineHeight: '30px'
  },
  '&.MuiTypography-pSemiBold': {
    fontFamily: 'Open Sans SemiBold',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#183561',
    marginTop: '16px'
  },

  ///
  '@media (max-width: 750px)': {
    '&.MuiTypography-title': {
      fontSize: '18px',
      lineHeight: '24px'
    },
    '&.MuiTypography-p': {
      fontSize: '16px',
      lineHeight: '24px'
    },
    '&.MuiTypography-pBold': {
      fontSize: '16px',
      lineHeight: '24px'
    },
    '&.MuiTypography-pSemiBold': {
      fontSize: '14px',
      marginTop: '6px'
    },
  }
}));