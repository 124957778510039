import * as React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionDetailsContent, AccordionDetailStyled, AccordionStyled, AccordionSummaryStyled, Link, Text } from './MobileMenu.styled';
import { Box } from '@mui/material';



function AccordionPanel({ id, summary, details, expanded, handleChange, handleDrawerToggle }) {
  return (
    <AccordionStyled expanded={expanded === id} onChange={handleChange(id)} disableGutters>
      <AccordionSummaryStyled
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${id}-content`}
        id={`${id}-header`}
      >
        <Text variant='title'>{summary}</Text>

      </AccordionSummaryStyled>
      <AccordionDetailStyled >
        {details.map((item, index) =>
          <AccordionDetailsContent key={index} >
            <Box sx={{
              height: '48px',
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
              <Link
                onClick={handleDrawerToggle}
                to={item.path}
                label={item.name}
              >
                {item.name}
              </Link>
            </Box>
          </AccordionDetailsContent>)}
      </AccordionDetailStyled>
    </AccordionStyled>
  );
}

export default function ControlledAccordion({ item, handleDrawerToggle }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <AccordionPanel
      id={item.title}
      summary={item.title}
      details={item.items}
      expanded={expanded}
      handleChange={handleChange}
      handleDrawerToggle={handleDrawerToggle}
    />
  );
}
