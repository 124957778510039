import React, { useRef } from "react";
import { LinkStyled } from "../Navbar.styled";
import triangle from './triangle.svg';
import './dropdown.css';

const Dropdown = ({ title, items }) => {
  const [focusItem, setFocusItem] = React.useState(false);
  const dropdownRef = useRef(null);

  const handleBlur = (e) => {
    if (!dropdownRef.current.contains(e.relatedTarget)) {
      setFocusItem(false);
    }
  };

  return (
    <li className='nav__listitem' ref={dropdownRef}>
      <span
        className='span_nav_item'
        tabIndex='0'
        onFocus={() => setFocusItem(true)}
      >
        {title}
      </span>
      <div className="not_visible" ></div>
      <ul
        className={
          focusItem
            ? 'nav__listitemdrop_services_focus'
            : 'nav__listitemdrop'
        }
        onClick={() => setFocusItem(false)}
        onMouseLeave={() => setFocusItem(false)}
        onBlur={handleBlur} 
      >
        <img src={triangle} alt='Triangle' className="triangle" />
        {items.map(({ name, path }, index) => (
          <li key={index}>
            <LinkStyled to={path} label={name}>{name}</LinkStyled>
          </li>
        ))}
      </ul>
    </li>
  );
};

export default Dropdown;
