import { Typography, Box, MobileStepper as _MobileStepper } from '@mui/material'
import { styled } from '@mui/system'
export const Wrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  marginBottom: '120px',
  '@media (max-width: 750px)': {
    marginBottom: '56px'
  }
}))

export const FormWrapper = styled(Box)(({ theme }) => ({
  maxWidth: '864px',
  width: '100%',
  height: '100%',
  background: 'linear-gradient(129deg, #F4CD00 -79.81%, #183561 95.58%)',
  borderRadius: '30px',
  padding: '32px 40px',
  marginTop: '80px',
  display: 'flex',
  minHeight: '480px',
  flexDirection: 'column',
  justifyContent: 'space-between',
  '@media (max-width: 1205px)': {
    marginTop: '40px'
  },
  '@media (max-width: 750px)': {
    padding: '16px '
  }
}))

export const FormText = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  fontFamily: 'Open Sans Regular',
  color: '#FFFFFF',
  '&.MuiTypography-h1': {
    fontWeight: 600,
    fontSize: '32px',
    lineHeight: '32px',
    margin: 0,
    padding:0
  },
  '&.MuiTypography-p': {
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '24px'
  },
  '&.MuiTypography-pDivider': {
    fontFamily: 'Inter Regular',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '20px'
  },

  ///
  '@media (max-width: 750px)': {
    '&.MuiTypography-h1': {
      fontSize: '16px',
      lineHeight: '24px'
    },
    '&.MuiTypography-p': {
      fontWeight: 400,
      fontSize: '14px'
    },
    '&.MuiTypography-pDivider': {
      fontSize: '14px'
    }
  }
}))

export const StepperWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  marginTop: '40px'
}))

export const MobileStepper = styled(_MobileStepper)(({ theme, activeStep }) => ({
  background: 'transparent',
  '& .MuiMobileStepper-dotActive': {
    backgroundColor: '#F4CD00',
    display: activeStep === 0 ? 'none' : 'block'
  },
  '& .MuiMobileStepper-dot': {
    margin: '0 8px',
    display: activeStep === 0 ? 'none' : 'block'
  },
  '& .MuiMobileStepper-progress': {
    backgroundColor: 'green'
  }
}))
