import { Box, Typography } from '@mui/material'
import styled from 'styled-components'

export const Wrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  marginBottom: '120px',
  '@media (max-width: 750px)': {
    marginBottom: '56px'
  }
}))

export const ContentWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  margin: '48px 0',
  '@media (max-width: 750px)': {
    margin: '18px 0 ',
  }
}))

export const ListWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start'
}))

export const ImgWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '48px',
  marginRight: '16px',
  '& img': {
    width: '100%'
  }
}))

export const ImgWrapper2 = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '864px',
  display: 'flex',
  justifyContent: 'center',
  '& img': {
    width: '100%'
  }
}))

export const FagsText = styled(Typography)(({ theme, color }) => ({
  fontStyle: 'normal',
  color: '#FFFFFF',
  '&.MuiTypography-h2': {
    fontFamily: 'Inter Medium',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '30px',
    marginBottom: '8px'
  },
  '&.MuiTypography-p': {
    fontFamily: 'Inter Regular',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '30px'
  },
  '&.MuiTypography-pT': {
    fontFamily: 'Inter Regular',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px'
  },

  '&.MuiTypography-contact': {
    fontFamily: 'Inter Regular',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '24px'
  },

  '&.MuiTypography-accTitle': {
    fontFamily: 'Open Sans Regular',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '30px',
    color,
    marginLeft: '24px'
  },
  '&.MuiTypography-accContent': {
    fontFamily: 'Open Sans Regular',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '30px',
    color
  },
  ///
  '@media (max-width: 750px)': {
    '&.MuiTypography-h2': {
      fontFamily: 'Open Sans Regular',
      fontWeight: 400,
      fontSize: '17px',
      lineHeight: '28px'
    },
    '&.MuiTypography-p': {
      fontFamily: 'Open Sans Regular',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '24px'
    },
    '&.MuiTypography-pT': {
      fontFamily: 'Open Sans Regular',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '24px'
    },
    '&.MuiTypography-accTitle': {
      fontSize: '17px',
      lineHeight: '28px',
      color,
      marginLeft: 0
    },
    '&.MuiTypography-accContent': {
      fontFamily: 'Inter Regular',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      color
    },
    '&.MuiTypography-contact': {
      fontFamily: 'Inter Regular',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '24px'
    },
  }
}))
