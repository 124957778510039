import { Box, Typography, Button } from '@mui/material';
import { borderRadius, maxWidth, minHeight, styled } from '@mui/system';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CustomLink from '../../../components/Link/Link';

export const CardWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: '77px',
  '@media (max-width: 750px)': {
    marginBottom: '56px'
  }
}));

export const Card = styled(Box)(({ theme }) => ({
  width: '100%',
  borderRadius: '8px',
  backgroundColor: '#FFF',
  maxHeight: '306px',
  height: '100%',
  marginTop: '80px',
  display: 'flex',
  justifyContent: 'space-between',
  '@media (max-width: 790px)': {
    flexDirection: 'column-reverse',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxHeight: '100%',
    marginTop: '24px',
  },
}));

export const CardContent = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '420px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: '72px 30px 30px 30px',
  '@media (max-width: 780px)': {
    padding: '21px 21px 12px 21px',
    minHeight: '144px'
  }
}));

export const CardImage = styled(Box)(({ theme }) => ({
  maxWidth: '456px',
  height: '100%',
  '& img': {
    '@media (max-width: 790px)': {
      width: '100%',
      borderRadius: '8px 0 0 8px'
    },

  }
}));

export const CardText = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  fontFamily: 'Open Sans Regular',
  color: '#183561;',
  '&.MuiTypography-h2': {
    fontFamily: 'Open Sans SemiBold',
    fontSize: '32px',
    lineHeight: '37px'
  },
  '&.MuiTypography-p': {
    marginTop: 4,
    fontSize: '16px',
    lineHeight: '21px'
  },

  ///
  '@media (max-width: 550px)': {
    '&.MuiTypography-h2': {
      fontSize: '20px',
      lineHeight: '21px',
    },
    '&.MuiTypography-p': {
      fontSize: '12px',
      lineHeight: '17px'
    }
  }
}));


export const CardLink = styled(CustomLink)(({ theme }) => ({
  fontFamily: 'Open Sans Bold',
  fontSize: '16px',
  lineHeight: '20px',
  color: '#183561',
  textTransform: 'none',
  maxWidth: '116px',
  width: '100%',
  height: '40px',
  display:'flex',
  justifyContent:'space-between',
  alignItems:'center',
  '@media (max-width: 790px)': {
    width: '100%',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
    height: '17px',
  },
  '&:hover' : {
    color: '#3A72C7'
  }

}));

