import React from 'react'
import Container from '../../components/General/Container'
import { MrWrapper, Wrapper } from './Complaints Policy.styled'
import { GeneralText } from '../../components/General/Text'

const ComplaintsPolicy = () => {
  return (
    <Container>
      <Wrapper>
        <GeneralText variant='h1'>Complaints Policy</GeneralText>
        <GeneralText variant='h2'>Introduction</GeneralText>
        <GeneralText variant='p'>
          At Study Tech, we pride ourselves on the quality of our training, and we want all of our
          students and clients to feel happy and satisfied with the service they receive. However,
          we recognise that sometimes we make mistakes and things don’t go to plan, and when that
          happens, we really want to hear about it. Your feedback allows us to improve our services
          further and correct anything we may have overlooked.
        </GeneralText>

        <GeneralText variant='h2'>Accessibility</GeneralText>
        <GeneralText variant='p'>
          If you require a copy of this information in an alternative format for accessibility
          purposes, don't hesitate to contact us to advise us of this, specifying the alternative
          format required. If you have any issues putting your complaint in writing, please ask a
          member of our team to take notes. Please ensure you agree with exactly what has been
          recorded, and our team member will then provide you with your own copy for your reference.
        </GeneralText>
        <GeneralText variant='h2'>Complaints procedure</GeneralText>

        <MrWrapper>
          <GeneralText variant='pSemibold'>Step 1: Contacting us</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>
            The first step is to talk to a member of the Study Tech team. This can be done quite
            informally by telephone.
          </GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>
            Usually, the best staff member to talk to will be the person who deals with the matter
            you are concerned about, as they will be in the best position to help you quickly and to
            put things right. If they are unavailable, or you would prefer to approach someone else,
            ask to speak to someone different.
          </GeneralText>
        </MrWrapper>

        <MrWrapper>
          <GeneralText variant='p'>
            We will try to resolve the problem on the spot if we can. If we can't do this, for
            example, because the information we need is not on hand, then we will take a record of
            your concern and arrange the best way and time to get back to you. This will normally be
            within five working days, or we will make another arrangement acceptable to you.
          </GeneralText>
        </MrWrapper>

        <MrWrapper>
          <GeneralText variant='pSemibold'> Step 2: Making a formal complaint</GeneralText>
        </MrWrapper>
        <GeneralText variant='p'>
          We hope you will only feel the need to make a formal complaint as a last resort and that
          you will complain to the person dealing with the matter first to give them a chance to put
          things right. However, if you are still unhappy, the next step is to put your complaint in
          writing to{' '}
          <GeneralText variant='p'>
            By email to{' '}
            <a href='mailto:complaints@study.tech ' className='email-link' aria-label='complaints@study.tech'>
              complaints@study.tech
            </a>
          </GeneralText>
        </GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>
            You will receive an acknowledgement of your complaint within one working day.
          </GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>
            We will investigate the complaint internally and provide a formal response by email
            within ten working days. We may be contractually obligated to copy your Funding Body
            into the complaint response email.
          </GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>
            We may need to contact you in the process of investigating your complaint to obtain
            further information.
          </GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='pSemibold'> Step 3. Escalation routes</GeneralText>
        </MrWrapper>
        <GeneralText variant='p'>
          If you remain dissatisfied with Study Tech’s response to your complaint and proposed
          resolution, you can escalate your Funding Body. Escalation routes available to you will be
          outlined in the complaint response email.
        </GeneralText>
      </Wrapper>
    </Container>
  )
}
export default ComplaintsPolicy
