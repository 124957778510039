import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const MainWrapper = styled(Box)(({ theme }) => ({
  maxWidth: '864px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  '@media (max-width: 750px)': {
    alignItems: 'center'
  }
}))
