import React from 'react';
import Container from '../../../components/General/Container';
import { ContentWrapper, FagsText, Wrapper } from '../Fags.styled';
import { GeneralText } from '../../../components/General/Text';
import AccordionStyled from '../Accordion';

const equipmentFaqs = [
  {
    title: 'What equipment can I upgrade?',
    content:
      `You can upgrade your computer, headset, printer, or monitor, provided the upgraded equipment does not compromise the original recommendation intent and works with the rest of your hardware and software.<br/><br/> Please know that you can only upgrade your computer before Study Tech ships your order. We cannot change the computer later in the DSA process.`
  },
  {
    title: `What do I have to pay?`,
    content: `Student Finance England requires you to pay a £200 contribution towards the cost of the recommended computer on your original quote. This contribution will be listed in your award letter. If applicable the upgrade cost will be added on top of this contribution - we will send you an upgrade list to show the full breakdown of what you will need to pay. `
  },
  {
    title: `What if I can’t afford to pay?`,
    content: `If you cannot pay the £200 contribution, you may be able to access hardship funds via your academic institution. Speak to your student services team at your University or College.`
  },
  {
    title: `How long will it take to get my order?`,
    content: `We aim to ensure that all orders are delivered promptly following approval from your Funding Body. However, if you request a computer upgrade, such as a custom-built laptop or a MacBook, the delivery time may be extended if there are increased shipping times from the manufacturer. In such cases, we will inform you of any delays as soon as we know them. Please note that custom-built laptops, custom upgrades, and MacBooks can occasionally take up to 6-8 weeks for delivery.`
  },
  {
    title: `My course requires a specific computer; would DSA cover this?`,
    content: `The computer that you have been recommended will be powerful enough to run the DSA software recommended for you. Your funding body cannot consider course requirements when agreeing to computers, as the funding is based on your disability needs. If your course requires a higher-specification computer, they ask you to cover the additional costs. We can provide you with upgrade options to consider.`
  },
  {
    title: `I’m unsure whether I want to upgrade; what should I do?`,
    content: `The standard laptop recommended can run all the DSA software recommended for you. However, you may wish to look at upgrading your computer. It will be yours to keep, and it is essential that you are happy with the machine. Please get in touch with us by phone or email; we would be happy to discuss your requirements and help you get the right computer for you.`
  },
  {
    title: `What happens if my equipment breaks?`,
    content: `If your equipment experiences any issues, we'll work with you to investigate the cause and determine if it's eligible for repair. You can contact our technical support team through:<br/>0204 532 2084 (select Option 4) <br/> <a href='mailto:techsupport@study.tech' class='email-link-dark' aria-label='techsupport@study.tech'>techsupport@study.tech</a>`
  }
];

const EquipmentFaqs = () => {
  return (
    <Container>
      <Wrapper>
        <>
          <GeneralText variant='h1'>FAQs Equipment & Software</GeneralText>
          {equipmentFaqs.map(item => (
            <AccordionStyled key={item.title} fag={item} />
          ))}
        </>
      </Wrapper>
    </Container>
  );
};

export default EquipmentFaqs;
