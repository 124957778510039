import React from 'react';
import Container from '../../components/General/Container';
import { MrWrapper, Wrapper } from './ModernSlaveryAct.styled';
import { GeneralText } from '../../components/General/Text';

const ModernSlaveryAct = () => {
  return (
    <Container>
      <Wrapper>
        <GeneralText variant='h1'>Modern Slavery Act</GeneralText>
        <GeneralText variant='h2'>Modern Slavery Policy</GeneralText>
        <GeneralText variant='p'>
          By the Modern Slavery Act 2015, Study Tech Limited (“Study Tech”) is committed to a work environment that is free from human trafficking, forced labour and unlawful child labour (collectively “human trafficking and slavery”). It also strongly believes that it has a responsibility to promote ethical and lawful employment practices.
        </GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>
            Accordingly, Study Tech will not knowingly use unlawful child labour or forced labour in any of the utilities and/or other commodities, products and/or services it provides. It will not accept commodities, products and/or services from suppliers that employ or utilise child labour or forced labour.
          </GeneralText>
        </MrWrapper>

        <GeneralText variant='h2'>Definitions</GeneralText>
        <GeneralText variant='p'>
          Human Trafficking: the recruitment, transportation, transfer, harbouring or receipt of
          persons using the threat or use of force or other forms of coercion, abduction, fraud,
          deception, the abuse of power or of a position of vulnerability or of the giving or
          receiving of payments or benefits to achieve the consent of a person having control over
          another person for exploitation. Forced Labour: all work or service, not voluntarily
          performed, obtained from an individual under the threat of force or penalty. Harmful Child
          Labour: consists of the employment of children that is economically exploitative or is
          likely to be hazardous to, or interfere with, the child’s education or to be harmful to
          the child’s health or physical, mental, spiritual, moral, or social development.
        </GeneralText>
        <GeneralText variant='h2'>Rationale</GeneralText>

        <MrWrapper>
          <GeneralText variant='p'>
            Human trafficking and slavery are crimes under UK and international law. These crimes
            exist in countries throughout the world. This policy statement thus defines StudyTech’s
            commitment to ensuring that human trafficking and slavery do not exist within its own
            business but also provides how StudyTech will make efforts to eradicate the same from
            other companies with whom it shall maintain a relationship (and especially from within
            its supply chain).
          </GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>
            StudyTech has appointed its Operations Director as its senior compliance officer (its
            Anti- Slavery and Human Trafficking Officer) and will take appropriate steps to ensure
            not only its compliance but also that these requirements are followed by its suppliers,
            subcontractors and/or business partners (collectively by its “Suppliers”).
          </GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>
            All Supplies are, therefore, required to adhere to the following:
          </GeneralText>
        </MrWrapper>
        <GeneralText variant='h2'>Requirements for Suppliers:</GeneralText>

        <MrWrapper>
          <ul>
            <li>
              <GeneralText variant='p'>
                Will not use forced or compulsory labour, i.e., any work or service that a worker
                performs involuntarily, under threat of penalty;
              </GeneralText>
            </li>
            <li>
              <GeneralText variant='p'>
                Will ensure that the overall terms of employment are voluntary;
              </GeneralText>
            </li>
            <li>
              <GeneralText variant='p'>
                Will comply with the minimum age requirements prescribed by applicable laws
              </GeneralText>
            </li>

            <li>
              <GeneralText variant='p'>
                Will compensate its workers with wages and benefits that meet or exceed the legally
                required minimum and will comply with overtime pay requirements;
              </GeneralText>
            </li>
            <li>
              <GeneralText variant='p'>
                Will abide by applicable law concerning the maximum hours of daily labour;
              </GeneralText>
            </li>
            <li>
              <GeneralText variant='p'>
                Will not engage in any practice of slavery, servitude, forced labour, compulsory
                labour and/or human trafficking outside the UK, which would constitute an offence if
                that conduct took place within the UK;
              </GeneralText>
            </li>
            <li>
              <GeneralText variant='p'>
                Will ensure that any sub-contractors or suppliers from whom they source goods and/or
                services for incorporation in those supplied to StudyTech also adhere to these
                requirements.
              </GeneralText>
            </li>
          </ul>
        </MrWrapper>

        <GeneralText variant='h2'>Certification</GeneralText>
        <GeneralText variant='p'>
          Suppliers will certify compliance with this Policy and their adherence to relevant human
          trafficking and slavery laws in each of the relevant countries in which they operate.
        </GeneralText>

        <GeneralText variant='h2'>Audits</GeneralText>
        <GeneralText variant='p'>
          Upon request, suppliers must be able to demonstrate compliance with this Policy to the
          reasonable satisfaction of StudyTech. StudyTech may perform periodic audits on this
          Policy, and Suppliers are expected to cooperate with any such audit fully.
        </GeneralText>

        <GeneralText variant='h2'>Reporting</GeneralText>
        <GeneralText variant='p'>
          Any breach of this Policy (including by a Supplier) can be reported (in confidence, if
          required) by contacting StudyTech’s Operations Director (in his capacity as StudyTech’s
          Anti-Slavery and Human Trafficking Officer).
        </GeneralText>

        <GeneralText variant='h2'>Consequences</GeneralText>
        <GeneralText variant='p'>
          StudyTech takes any breach of this Policy exceptionally seriously.
        </GeneralText>

        <MrWrapper>
          <GeneralText variant='p'>
            Suppliers who are found to have or be engaging in human trafficking and slavery or who
            refuse to cooperate with any audit to verify compliance with this Policy will be liable
            to have any supply agreement, arrangement or other contract with StudyTech terminated
            immediately, without compensation.
          </GeneralText>
        </MrWrapper>

        <MrWrapper>
          <GeneralText variant='p'>
            Suppose a Supplier to StudyTech is found in violation of this policy. In that case,
            StudyTech will take prompt action, which may include terminating any supply agreement,
            arrangement, or other contract with that Supplier (as above). It shall also take such
            other (remedial) steps as the Anti-Slavery and Human Trafficking Officer shall determine
            to be necessary to address the violation and seek to prevent its reoccurrence
          </GeneralText>
        </MrWrapper>
      </Wrapper>
    </Container>
  );
};

export default ModernSlaveryAct;
