import { Box, Container } from '@mui/material'
import React from 'react'
import { MrWrapper, Wrapper } from './PrivacyPolicy.styled'
import { GeneralText } from '../../components/General/Text'

const PrivacyPolicy = () => {
  return (
    <Container>
      <Wrapper>
        <GeneralText variant='h1'>Privacy Policy</GeneralText>
        <Box sx={{ mb: '40px' }}>
          <GeneralText variant='p'>
          Study Tech is committed to processing personal data about its customers in ways that comply with its legal and regulatory obligations, and to be clear with our customers about what it does with their personal data. We respect and value the privacy of everyone who enquires or participates in our services and will only collect and use data in ways that are useful to you in the arrangement and delivery of requested services and in a manner consistent with your rights and our obligations under the law.
          </GeneralText>
        </Box>
        <GeneralText variant='h2'>The purpose of this document</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>
          The primary purpose of a privacy policy is to ensure Study Tech complies with information disclosure obligations under data protection legislation. From 25 May 2018 in the UK and elsewhere in the EU, that includes the General Data Protection Regulation (Regulation (EU) 2016/679) (GDPR). Failure to comply may lead to civil liability and/or criminal law penalties.
          </GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>
            StudyTech, hereinafter, referred to as “We”, “Us” and “Our” unless specified otherwise.
          </GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>
            <a
              href='https://ico.org.uk/for-organisations/uk-gdpr-guidance-and-resources/'
              className='email-link'
              target='_blank' rel="noreferrer">
              The latest GDPR policy is available via this link
            </a>{' '}
          </GeneralText>
        </MrWrapper>
        <GeneralText variant='h2'>General Data Protection Regulation</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>
            In collecting and using data, StudyTech must comply with the GDPR requirements as it
            governs the processing of personal data. StudyTech is identified as a Data Controller
            under the legislation and the Contracts and Compliance Officer is our Data Protection
            Officer. The personal data provided to us during the supply of equipment, installation
            and support is securely held by us on a central computer facility and will be treated
            confidentially and with sensitivity for the benefit of the company and staff so our
            commitments can be fulfilled.
          </GeneralText>
        </MrWrapper>
        <GeneralText variant='h2'>Data Protection Principles</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>
            StudyTech is committed to processing data in accordance with its responsibilities under
            the GDPR.
          </GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>
            Article 5 of the GDPR requires that personal data shall be:
          </GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>
            A. Processed lawfully, fairly and in a transparent manner in relation to individuals;
          </GeneralText>
        </MrWrapper>

        <MrWrapper>
          <GeneralText variant='p'>
            B. Collected for specified, explicit and legitimate purposes and not further processed
            in a manner that is incompatible with those purposes; further processing for archiving
            purposes in the public interest, scientific or historical research purposes or
            statistical purposes shall not be considered to be incompatible with the initial
            purposes;
          </GeneralText>
        </MrWrapper>

        <MrWrapper>
          <GeneralText variant='p'>
            C. Adequate, relevant and limited to what is necessary for relation to the purposes for
            which they are processed;
          </GeneralText>
        </MrWrapper>

        <MrWrapper>
          <GeneralText variant='p'>
            D. Accurate and, where necessary, kept up to date; every reasonable step must be taken
            to ensure that personal data that are inaccurate, having regard to the purposes for
            which they are processed, are erased or rectified without delay;
          </GeneralText>
        </MrWrapper>

        <MrWrapper>
          <GeneralText variant='p'>
            E. Kept in a form which permits identification of data subjects for no longer than is
            necessary for the purposes for which the personal data are processed; personal data may
            be stored for longer periods insofar as the personal data will be processed solely for
            archiving purposes in the public interest, scientific or historical research purposes or
            statistical purposes subject to the implementation of the appropriate technical and
            organisational measures required by the GDPR in order to safeguard the rights and
            freedoms of individuals; and
          </GeneralText>
        </MrWrapper>

        <MrWrapper>
          <GeneralText variant='p'>
            F. Processed in a manner that ensures appropriate security of the personal data,
            including protection against unauthorised or unlawful processing and against accidental
            loss, destruction or damage, using appropriate technical or organisational measures.
          </GeneralText>
        </MrWrapper>
        <GeneralText variant='h2'>General Provisions</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>
            A. This policy applies to all personal data processed by us.
          </GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>
            B. The Responsible Person shall take responsibility for our ongoing compliance with this
            policy.
          </GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>C. This policy shall be reviewed at least annually.</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>
            D. We shall continually register with the Information Commissioner’s Office as an
            organisation that processes personal data.
          </GeneralText>
        </MrWrapper>

        <GeneralText variant='h2'>Lawful, Fair and Transparent Processing</GeneralText>

        <MrWrapper>
          <GeneralText variant='p'>
            A. To ensure its processing of data is lawful, fair and transparent, we shall maintain a
            Register of Systems.
          </GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>
            B. The Register of Systems shall be reviewed at least annually.
          </GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>
            C. Individuals have the right to access their personal data and any such requests made
            to us shall be dealt with in a timely manner.
          </GeneralText>
        </MrWrapper>

        <GeneralText variant='h2'>Lawful Purposes</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>
            A. All data processed by StudyTech must be done on one of the following lawful bases:
            consent, contract, legal obligation, vital interests, public task or legitimate
            interests (see ICO guidance for more information).
          </GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>
            B. StudyTech shall note the appropriate lawful basis in the Register of Systems.
          </GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>
            C. Where consent is relied upon as a lawful basis for processing data, evidence of
            opt-in consent shall be kept with the personal data.
          </GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>
            D. Where communications are sent to individuals based on their consent, the option for
            the individual to revoke their consent will be clearly available and systems will be in
            place to ensure such revocation is reflected.
          </GeneralText>
        </MrWrapper>

        <GeneralText variant='h2'>Data Minimisation</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>
            A. We shall ensure that personal data is adequate, relevant and limited to what is
            necessary for relation to the purposes for which they are processed.
          </GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>
            B. We will only collect the required data to ensure procurement policies and
            requirements are adhered to and to ensure we can continue the agreed length of
            contractual agreements thereafter.
          </GeneralText>
        </MrWrapper>

        <GeneralText variant='h2'>Accuracy</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>
            A. We shall take reasonable steps to ensure personal data is accurate.
          </GeneralText>
        </MrWrapper>

        <MrWrapper>
          <GeneralText variant='p'>
            B. Where necessary for the lawful basis on which data is processed, steps shall be put
            in place to ensure that personal data is kept up to date.
          </GeneralText>
        </MrWrapper>

        <GeneralText variant='h2'>Individual’s Rights</GeneralText>

        <MrWrapper>
          <GeneralText variant='p'>
            Under GDPR, we acknowledge the following rights of the individual, in respect of any
            personal data that we hold:
          </GeneralText>
        </MrWrapper>

        <MrWrapper>
          <GeneralText variant='p'>A. The right to be informed</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>B. The right of access</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>C. The right to rectification</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>D. The right to erasure</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>E. The right to restrict processing</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>F. The right to data portability</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>G. The right to object</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>
            H. The right not to be subject to automated decision-making including profiling
          </GeneralText>
        </MrWrapper>

        <GeneralText variant='h2'>Archiving / Removal</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>
            A. To ensure that personal data is kept for no longer than necessary, we shall put in
            place an archiving policy for each area in which personal data is processed and review
            this process annually.
          </GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>
            B. The archiving policy shall consider what data should/must be retained, for how long,
            and why.
          </GeneralText>
        </MrWrapper>

        <GeneralText variant='h2'>Security</GeneralText>

        <MrWrapper>
          <GeneralText variant='p'>
            B. The archiving policy shall consider what data should/must be retained, for how long,
            and why.
          </GeneralText>
        </MrWrapper>

        <MrWrapper>
          <GeneralText variant='p'>
            A. We shall ensure that personal data is stored securely using modern software that is
            kept-up-to-date.
          </GeneralText>
        </MrWrapper>

        <MrWrapper>
          <GeneralText variant='p'>
            B. Access to personal data shall be limited to personnel who need access and appropriate
            security should be in place to avoid unauthorised sharing of information.
          </GeneralText>
        </MrWrapper>

        <MrWrapper>
          <GeneralText variant='p'>
            C. When personal data is deleted this should be done safely such that the data is
            irrecoverable.
          </GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>
            D. Appropriate back-up and disaster recovery solutions shall be in place.
          </GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>E. Breach</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>
          F. In the event of a breach of security leading to the accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access to, personal data, we shall promptly assess the risk to people’s rights and freedoms and if appropriate report this breach to the ICO (more information on the ICO website).
          </GeneralText>
        </MrWrapper>
      </Wrapper>
    </Container>
  )
}

export default PrivacyPolicy
