import React, { useState } from 'react'
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Box } from '@mui/material'
import { Controller } from 'react-hook-form'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  radioLabel: {
    fontFamily: 'Inter regular',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    color: '#FFF'
  },
  formControl: {
    marginTop: '16px',
    '& .MuiRadio-root': {
      color: '#FFF',
      '&.Mui-checked': {
        color: '#FFF'
      }
    },
    '& .MuiIconButton-root': {
      color: '#FFF'
    }
  }
})

export const ControlledRadioButton = ({
  control,
  name,
  label,
  options,
  required,
  error,
  helperText,
  rules,
  disabled = false,
  defaultValue
}) => {
  const classes = useStyles();
  const initialValue = defaultValue || ''; 
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {error && <Box sx={{ alignSelf: 'flex-end', color: '#FF9E9E' }}>{helperText}</Box>}
      <Controller
        control={control}
        rules={rules}
        name={name}
        defaultValue={initialValue} 
        render={({ field: { onChange, value } }) => (
          <FormControl component='fieldset' className={classes.formControl}>
            <RadioGroup
              name={name}
              value={value} // Use value from react-hook-form
              onChange={e => onChange(e.target.value)}>
              {options.map(option => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<Radio />}
                  label={option.label}
                  classes={{ label: classes.radioLabel }}
                  disabled={disabled}
                />
              ))}
            </RadioGroup>
          </FormControl>
        )}
      />
    </Box>
  );
};
