import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledLink = styled(Link)(({ theme, color }) => ({
  textDecoration: 'none',
  fontFamily: 'Open Sans Bold',
  color
}));

const CustomLink = ({ children, to, label = '', ...props }) => {
  return (
    <StyledLink to={to} aria-label={label} {...props}>
      {children}
    </StyledLink>
  );
};

export default CustomLink;