import React from 'react'
import { Checkbox, FormControlLabel, FormControl, FormLabel, Box } from '@mui/material'
import { Controller } from 'react-hook-form'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  checkboxLabel: {
    fontFamily: 'Inter regular',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    color: '#FFF',
    paddingLeft: 3,
    height: '20px'
  },
  formControl: {
    marginTop: 0,
    padding: 0,
    '& .MuiCheckbox-root': {
      paddingY: 0,
      height: '20px',

      color: '#FFF',
      '&.Mui-checked': {
        color: '#FFF'
      }
    },
    '& .MuiIconButton-root': {
      color: '#FFF'
    }
  }
})

export const ControlledCheckbox = ({
  control,
  name,
  label,
  required,
  error,
  helperText,
  rules,
  disabled = false,
  defaultValue = false,
  setValue
}) => {
  const classes = useStyles()

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {error && <Box sx={{ alignSelf: 'flex-end', color: '#FF9E9E' }}>{helperText}</Box>}
      <Controller
        control={control}
        rules={rules}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { onChange, value } }) => (
          <FormControl component='fieldset' className={classes.formControl} name={name}>
            <FormControlLabel
              name={name}
              control={
                <Checkbox
                  name={name}
                  checked={!!value}
                  onChange={e => {
                    onChange(e.target.checked)
                    setValue && setValue(e.target.checked)
                  }}
                  disabled={disabled}
                />
              }
              label={label}
              classes={{ label: classes.checkboxLabel }}
            />
          </FormControl>
        )}
      />
    </Box>
  )
}
