import React from 'react'
import { Stack } from '@mui/material'
import { FormText } from '../PreAssessmentForm.styled'
import { ControlledTextField } from '../../../components/inputs/ControlledTextField'
import { ControlledCheckbox } from '../../../components/inputs/ControlledCheckbox'

const TravelStep = ({ control, errors }) => {
  return (
    <>
      <FormText variant='h1' sx={{ mb: 3 }}>
        Travel
      </FormText>
      <FormText variant='pDivider'>
        Please let us know if you are currently receiving/using the following (select as many as
        applicable)
      </FormText>
      <Stack direction={'column'} spacing={2} sx={{ mt: 3 }}>
        <>
          <ControlledCheckbox
            control={control}
            name='disabilityLivingAllowanceTravel'
            label='Disability Living Allowance (DLA)'
          />
          <ControlledCheckbox
            control={control}
            name='bursaryEquipmentProvidedTravel'
            label='Bursary/Equipment Provided by HEI'
          />
          <ControlledCheckbox control={control} name='mobilityCarTravel' label='Mobility Car' />
          <ControlledCheckbox control={control} name='otherTravel' label='Other' />
          <ControlledTextField
            variant='standard'
            control={control}
            name='otherComments'
            label='Open comments'
            multiline
            minRows={2}
          />
        </>
      </Stack>
    </>
  )
}

export default TravelStep
