import React from 'react';
import { Route, Routes, Navigate } from 'react-router';
import Main from '../pages/Main/Main';
import { Box, CssBaseline } from '@mui/material';
import styled from 'styled-components';
import Footer from '../components/Footer/Footer';
import About from '../pages/About/about-us/About';
import DsaStudents from '../pages/Dsa-students/DsaStudents';
import Careers from '../pages/Careers/Careers';
import ComplaintsPolicy from '../pages/Complaint-policy/ComplaintsPolicy';
import ContactUs from '../pages/Contact-us/ContactUs';
import PrivacyPolicy from '../pages/Privacy-policy/PrivacyPolicy';
import Navbar from '../components/NavBar/Navbar';
import StudentServicesAssessment from '../pages/Student-services/student-services-assessment/StudentServicesAssessment';
import EquipmentSoftware from '../pages/Student-services/student-services-equipment-software/EquipmentSoftware';
import StudentServicesAtTraining from '../pages/Student-services/student-services-at-training/StudentServicesAtTraining';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Toaster } from 'react-hot-toast';
import PreAssessmentForm from '../pages/Pre-assessment-form/PreAssessmentForm';
import AtTrainingFaqs from '../pages/Fags/at-training/AtTrainingFaqs';
import EquipmentFaqs from '../pages/Fags/equipment.js/EquipmentFaqs';
import AssessmentFaqs from '../pages/Fags/assessment/AssessmentFaqs';
import CookiePolicy from '../pages/Cookie-policy/CookiePolicy';
import Accessibility from '../pages/Accessibility/Accessibility';
import ModernSlaveryAct from '../pages/Modern-slavery-act/ModernSlaveryAct';
import ErgonomicAssessment from '../pages/Student-services/student-services-ergonomic-assessment/ErgonomicAssessment';
import ErgonomicFags from '../pages/Fags/ergonomic/ErgonomicFags';
import TermsConditions from '../pages/Terms-conditions/TermsConditions';
import RefundPolicy from '../pages/Refund-policy/RefundPolicy';
import Sitemap from '../pages/Sitemap/Sitemap';

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  maxWidth: '100%',
  backgroundColor: '#183561',
  boxSizing: 'content-box',
  overflowX: 'hidden',
  '@media (max-width:200px)': {
    minHeight: '110vh'
  }
}));

export default function MainRouter() {
  return (
    <Wrapper>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
     
        <Routes>
          <Route path='/' element={<Main />} />
          <Route path='/about' element={<About />} />
          <Route path='/student-services-assessments' element={<StudentServicesAssessment />} />
          <Route path='/student-services-equipment-software' element={<EquipmentSoftware />} />
          <Route path='/student-services-at-training' element={<StudentServicesAtTraining />} />
          <Route path='/student-services-ergonomic-assessment' element={<ErgonomicAssessment />} />
          <Route path='/frequently-asked-questions-at-training' element={<AtTrainingFaqs />} />
          <Route path='/frequently-asked-questions-assessments' element={<AssessmentFaqs />} />
          <Route path='/frequently-asked-questions-equipment' element={<EquipmentFaqs />} />
          <Route path='/frequently-asked-questions-ergonomics' element={<ErgonomicFags />} />
          <Route path='/pre-assessment-form' element={<PreAssessmentForm />} />
          <Route path='/careers' element={<Careers />} />
          <Route path='/contact' element={<ContactUs />} />
          <Route path='/complaints-policy' element={<ComplaintsPolicy />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/cookie-policy' element={<CookiePolicy />} />
          <Route path='/dsa-information' element={<DsaStudents />} />
          <Route path='/accessibility' element={<Accessibility />} />
          <Route path='/modern-slavery-act' element={<ModernSlaveryAct />} />
          <Route path='/terms-and-conditions' element={<TermsConditions />} />
          <Route path='/refund-policy' element={<RefundPolicy />} />
          <Route path='/sitemap' element={<Sitemap />} />
          <Route path='*' element={<Navigate to='/' replace />} />
        </Routes>
       
        <Toaster position='top-right' toastOptions={{ duration: 4000 }} />
      </LocalizationProvider>
    </Wrapper>
  );
}
