import React from 'react';
import {
  Certificates,
  DividerStyled,
  DividerStyledMobile,
  FooterContainerStyled,
  FooterContent,
  FooterDown,
  FooterDownLeftSide,
  FooterLogo,
  Link,
  Text,
  Wr,
  Wrapper
} from './Footer.styled';
import footer_logo from '../../../assets/icons/navbar/Logo.svg';
import CustomLink from '../Link/Link';
import { Box } from '@mui/material';
import certificate1 from '../../../assets/icons/footer/certificate1.svg';
import certificate2 from '../../../assets/icons/footer/certificate2.svg';
import certificate3 from '../../../assets/icons/footer/certificate3.svg';
import certificate4 from '../../../assets/icons/footer/certificate4.svg';
import certificate5 from '../../../assets/icons/footer/certificate5.svg';

const certificates = [
  { id: 'ISO 9001 logo Alt Text: ISO 9001 Quality Management Systems Certification mark', certificate: certificate1, file: '/ISO_9001_2015_Certificate.pdf' },
  { id: 'ISO 14001 logo Alt Text: ISO 14001 Environmental Management Systems Certification mark', certificate: certificate2, file: '/ISO_14001_2015_Certificate.pdf' },
  { id: 'ISO 27001 logo Alt Text: ISO 27001 Information Security Management Systems Certification mark', certificate: certificate3, file: '/ISO_27001_2022_Certificate.pdf' },
  { id: 'Disability Confident Committed logo Alt Text: Disability Confident Committed employer certification mark', certificate: certificate4 },
  { id: 'Social Enterprise Alt Text: Social Enterprise UK official membership mark', certificate: certificate5 },

];


const columnsData = [
  {
    title: 'Student Services',
    items: [
      { name: 'Needs Assessments', path: '/student-services-assessments', sx: '16px' },
      { name: 'Equipment and Software', path: '/student-services-equipment-software', sx: '16px' },
      { name: 'AT Training', path: '/student-services-at-training', sx: '16px' },
      { name: 'Ergonomic Assessments', path: '/student-services-ergonomic-assessment', sx: '16px' },
    ]
  },
  {
    title: 'DSA Information',
    items: [
      { name: 'DSA Information', path: '/dsa-information', sx: '16px' }
    ]
  },
  {
    title: 'FAQs',
    items: [
      { name: 'Needs Assessments', path: '/frequently-asked-questions-assessments', sx: '16px' },
      { name: 'Equipment and Software', path: '/frequently-asked-questions-equipment', sx: '16px' },
      { name: 'AT Training', path: '/frequently-asked-questions-at-training', sx: '16px' },
      { name: 'Ergonomic Assessments', path: '/frequently-asked-questions-ergonomics', sx: '16px' },

    ]
  },
  {
    title: 'Legal',
    items: [
      { name: 'Privacy Policy', path: '/privacy-policy', sx: '16px' },
      { name: 'Cookie Policy', path: '/cookie-policy', sx: '16px' },
      { name: 'Complaints Policy', path: '/complaints-policy', sx: '16px' },
      { name: 'Accessibility', path: '/accessibility', sx: '16px' },
      { name: 'Modern Slavery Act', path: '/modern-slavery-act', sx: '16px' },
      { name: 'Terms and Conditions', path: '/terms-and-conditions', sx: '16px' },
      { name: 'Refund Policy', path: '/refund-policy', sx: '16px' }
    ]
  },
  {
    title: 'Company',
    items: [
      { name: 'About Us', path: '/about', sx: '16px' },
      { name: 'Careers', path: '/careers' }
    ]
  },
  {
    title: 'Contact',
    items: [
      { name: '0204 532 2084', path: 'tel:0204 532 2084', sx: '16px', },
      { name: 'assessments@study.tech', path: 'mailto:assessments@study.tech', underline: 'underline' },
      { name: 'orders@study.tech', path: 'mailto:orders@study.tech', underline: 'underline' },
      { name: 'att@study.tech', path: 'mailto:att@study.tech', underline: 'underline' },
      { name: 'techsupport@study.tech', path: 'mailto:techsupport@study.tech', underline: 'underline' },
      { name: 'ergo@study.tech', path: 'mailto:ergo@study.tech', sx: '16px', underline: 'underline' },
      { name: 'media@study.tech', path: 'mailto:media@study.tech', underline: 'underline' },
      { name: 'opportunity@study.tech', path: 'mailto:opportunity@study.tech', underline: 'underline' }
    ]
  }
];

const Footer = () => {
  return (
    <Wr>
      <Wrapper>
        <DividerStyled />
        <FooterContainerStyled>
          <Box>
            <FooterLogo>
              <CustomLink to='/' label='Study Tech home'>
                <img src={footer_logo} alt='Study Tech home' />
              </CustomLink>
            </FooterLogo>
          </Box>

          <FooterContent>
            {columnsData.map(({ title, items }, index) => (
              <Box key={index}>
                <Box sx={{ marginBottom: '16px' }}>
                  <Text variant='subtitle' >{title}</Text>
                </Box>
                {items.map(({ name, path, sx, underline }, indexItem) => (
                  <Box key={indexItem} sx={{ marginBottom: sx }}>
                    <Link to={path} underline={underline}>{name}</Link>
                  </Box>
                ))}
              </Box>
            ))}
          </FooterContent>

        </FooterContainerStyled>
        <FooterDown>
          <FooterDownLeftSide>
            <Certificates>
              {certificates.map((item) => (
                <a
                  key={item.id}
                  href={item.file}
                  aria-label={item.id}
                  target='_blank' rel="noreferrer"
                >
                  <img src={item.certificate} alt={item.id} />
                </a>
              ))}
            </Certificates>

            <Box>
              <DividerStyledMobile />
              <Link to='/sitemap'>Sitemap</Link>
            </Box>

          </FooterDownLeftSide>
          <Text variant='p'>©2024 Study Tech</Text>

        </FooterDown>
        <DividerStyled />
      </Wrapper >
    </Wr>
  );
};

export default Footer;
