import React from 'react'
import { Stack } from '@mui/material'
import { FormText } from '../PreAssessmentForm.styled'
import { ControlledCheckbox } from '../../../components/inputs/ControlledCheckbox'

const InformCheckbox = ({ control, errors, watch }) => {
  const dontHaveSupport = watch('dontHaveSupport')

  return (
    <Stack direction={'column'} spacing={4}>
      <FormText variant='h1'>
        Please select any support your University/College has put in place for you already.
      </FormText>
      <Stack direction={'column'} spacing={3} sx={{ mt: 2 }}>
        <ControlledCheckbox
          control={control}
          name='dontHaveSupport'
          label='I do not have any support in place yet'
        />
        {!dontHaveSupport && (
          <>
            <ControlledCheckbox
              control={control}
              name='deadlineExtensions'
              label='Deadline extensions'
            />
            <ControlledCheckbox
              control={control}
              name='accessLecture'
              label='Access to Lecture Slides'
            />
            <ControlledCheckbox
              control={control}
              name='longerLibrary'
              label='Longer library loans'
            />
            <ControlledCheckbox control={control} name='examsComputer' label='Exams - Computer' />
            <ControlledCheckbox
              control={control}
              name='examsExtraTime'
              label='Exams - Extra time'
            />
            <ControlledCheckbox
              control={control}
              name='examsReaderScribe'
              label='Exams - Reader Scribe'
            />

            <ControlledCheckbox
              control={control}
              name='examsSeparateRoom'
              label='Exams - Separate Room'
            />
            <ControlledCheckbox
              control={control}
              name='examRestBreaks'
              label='Exam - Rest Breaks'
            />
            <ControlledCheckbox
              control={control}
              name='studySupportTutor'
              label='Study Support Tutor'
            />
            <ControlledCheckbox control={control} name='mentor' label='Mentor' />
            <ControlledCheckbox control={control} name='notetaker' label='Notetaker' />
            <ControlledCheckbox control={control} name='bslInterpreter2' label='BSL interpreter' />
          </>
        )}
      </Stack>
      <FormText variant='h1'>
        Please select the various ways in which your course is taught.(select as many as applicable)
      </FormText>
      <Stack direction={'column'} spacing={3} sx={{ mt: 2 }}>
        <ControlledCheckbox control={control} name='lectures' label='Lectures' />
        <ControlledCheckbox control={control} name='seminars' label='Seminars' />
        <ControlledCheckbox control={control} name='writtenCourseWork' label='Written Coursework' />
        <ControlledCheckbox
          control={control}
          name='practicalCourseWork'
          label='Practical Coursework'
        />
        <ControlledCheckbox control={control} name='presentations' label='Presentations' />
        <ControlledCheckbox control={control} name='interviews' label='Interviews' />
        <ControlledCheckbox control={control} name='timedExaminations' label='Timed Examinations' />
        <ControlledCheckbox control={control} name='studyPlacement' label='Study Placement' />
        <ControlledCheckbox control={control} name='placementYear' label='Placement Year' />
        <ControlledCheckbox
          control={control}
          name='libraryInternetResearch'
          label='Library/ Internet Research'
        />
        <ControlledCheckbox
          control={control}
          name='workshopLabSessions'
          label='Workshop/ Lab Sessions'
        />
        <ControlledCheckbox control={control} name='fieldTrips' label='Field Trips' />
      </Stack>
    </Stack>
  )
}

export default InformCheckbox
