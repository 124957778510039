import styled from '@emotion/styled'
import { Typography } from '@mui/material'

export const GeneralText = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  color: '#FFFFFF',
  '&.MuiTypography-h1': {
    fontFamily: 'Open Sans Bold',
    fontWeight: 700,
    fontSize: '45px',
    lineHeight: '52px',
    marginTop: '120px',
    marginBottom: '80px'
  },
  '&.MuiTypography-h2': {
    fontFamily: 'Open Sans SemiBold',
    fontWeight: 600,
    fontSize: '32px',
    lineHeight: '38px',
    marginBottom: '24px',
    marginTop: '24px'
  },
  '&.MuiTypography-h3': {
    fontFamily: 'Open Sans SemiBold',
    fontWeight: 600,
    fontSize: '30px',
    lineHeight: '30px',
    margin: '24px 0'
  },
  '&.MuiTypography-h4': {
    fontFamily: 'Open Sans SemiBold',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '30px',
  },
  '&.MuiTypography-p': {
    fontFamily: 'Open Sans Regular',
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '30px'
  },
  '&.MuiTypography-pSemibold': {
    fontFamily: 'Open Sans SemiBold',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '30px'
  },
  '&.MuiTypography-pBold': {
    fontFamily: 'Open Sans Bold',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '30px',
  },
  '&.MuiTypography-bold': {
    fontFamily: 'Open Sans Bold',
    fontSize: '20px',
    lineHeight: '30px',
  },
  ///
  '@media (max-width: 750px)': {
    '&.MuiTypography-h1': {
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '25px',
      marginTop: '32px',
      marginBottom: '32px'
    },
    '&.MuiTypography-h2': {
      fontFamily: 'Open Sans SemiBold',
      fontWeight: 600,
      fontSize: '17px',
      lineHeight: '30px',
      marginBottom: '20px'
    },
    '&.MuiTypography-h3': {
      fontFamily: 'Open Sans SemiBold',
      fontWeight: 600,
      fontSize: '17px',
      lineHeight: '30px',
      margin: '20px 0'
    },
    '&.MuiTypography-h4': {
      fontFamily: 'Open Sans SemiBold',
      fontWeight: 600,
      fontSize: '15px',
      lineHeight: '30px',
      margin: '10px 0'
    },
    '&.MuiTypography-p': {
      fontFamily: 'Open Sans Regular',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '24px'
    },
    '&.MuiTypography-pSemibold': {
      fontFamily: 'Open Sans Regular',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '24px'
    },
    '&.MuiTypography-pBold': {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px'
    },
    '&.MuiTypography-bold': {
      fontFamily: 'Open Sans Regular',
      fontSize: '16px',
      lineHeight: '24px',
    },
  }
}))
