import React from 'react'
import { Box, Stack } from '@mui/material'
import { FormText } from '../PreAssessmentForm.styled'
import { ControlledTextField } from '../../../components/inputs/ControlledTextField'
import { ControlledAutocomplete } from '../../../components/inputs/ControlledAutocomplete'
import { ControlledRadioButton } from '../../../components/inputs/ControlledRadioButton'
const hasLaptopOptions = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' }
]

const processorOptions = [
  { label: 'i3 10th generation or above', value: 'i3 10th generation or above' },
  { label: 'i3 lower than 10th generation', value: 'i3 lower than 10th generation' },
  { label: 'i5 10th generation or above', value: 'i5 10th generation or above' }
]

const ramOptions = [
  { label: '4 GB', value: '4 GB' },
  { label: '8 GB', value: '8 GB' },
  { label: '12 GB', value: '12 GB' },
  { label: '16 GB or more', value: '16 GB or more' }
]

const storageOptions = [
  { label: '128 GB', value: '128 GB' },
  { label: '256 GB', value: '256 GB' },
  { label: '512 GB', value: '512 GB' },
  { label: '1 TB or more', value: '1 TB or more' }
]

export const storageTypeOptions = [
  {
    label: 'SSD - Solid State Drive',
    value: 'SSD - Solid State Drive'
  },
  {
    label: 'HDD - Hard Disk Drive',
    value: 'HDD - Hard Disk Drive'
  }
]

const laptopConditions = [
  { label: 'Brand new', value: 'Brand new' },
  { label: 'Good as new', value: 'Good as new' },
  { label: 'Average', value: 'Average' },
  { label: 'Poor', value: 'Poor' }
]

const LaptopSpecification = ({ control, errors, watch }) => {
  const hasLaptop = watch('hasLaptop')
  return (
    <Box>
      <FormText variant='h1'> Do you currently own a Computer or Laptop?</FormText>
      <Stack direction={'column'} spacing={3} sx={{ mt: 2 }}>
        <ControlledRadioButton
          name='hasLaptop'
          options={hasLaptopOptions}
          control={control}
          defaultValue={'no'}
        />
        {hasLaptop === 'yes' && (
          <>
            <ControlledTextField
              variant='standard'
              control={control}
              name='computerMakeAndModel'
              label='Computer Make and Model (e.g. Lenovo V15 82NB003LUK)'
              multiline
              minRows={2}
            />
            <FormText variant='h1'>Processor</FormText>
            <ControlledRadioButton name='processor' options={processorOptions} control={control} defaultValue={'i3 10th generation or above'}/>
            <FormText variant='h1'>RAM</FormText>
            <ControlledRadioButton name='ram' options={ramOptions} control={control} defaultValue={'4 GB'}/>
            <FormText variant='h1'>Storage capacity</FormText>
            <ControlledRadioButton
              name='storageCapacity'
              options={storageOptions}
              control={control}
              defaultValue={'128 GB'}
            />
            <ControlledAutocomplete
              control={control}
              name='storageType'
              label='Storage type*'
              options={storageTypeOptions}
              rules={{ required: true }}
              error={!!errors.storageType}
              helperText='Storage type is required'
              defaultValue='SSD - Solid State Drive'
            />
            <ControlledTextField
              variant='standard'
              control={control}
              name='operatingSystem'
              label='Operating System (e.g. Windows 10)'
            />
            <FormText variant='h1'>Laptop Condition*</FormText>
            <ControlledRadioButton
              name='laptopCondition'
              options={laptopConditions}
              control={control}
              defaultValue={'Brand new'}
            />
          </>
        )}
      </Stack>
    </Box>
  )
}

export default LaptopSpecification
