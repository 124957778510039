import React, { useState } from 'react'
import Container from '../../components/General/Container'
import { FormWrapper, MobileStepper, StepperWrapper, Wrapper } from './PreAssessmentForm.styled'
import { useForm } from 'react-hook-form'
import ContactDetailStep from './Contact-detail-step/ContactDetailStep'
import GenderStep from './One-field-steps/GenderStep'
import WelcomeStep from './Welcome-step/WelcomeStep'
import MedicalEvidenceStep from './Medical-evidence-step/MedicalEvidenceStep'
import InformCheckbox from './One-field-steps/InformCheckbox'
import ExistingAssistiveStep from './Existing-assistive-technology/ExistingAssistiveStep'
import LaptopSpecification from './Laptop-specification/LaptopSpecification'
import DsaAwardStep from './Dsa-award-step/DsaAwardStep'
import TravelStep from './Travel-step/TravelStep'
import FinishStep from './Finish-step/FinishStep'
import { Button } from '@mui/material'
import toast from 'react-hot-toast'
import { EMAILJS_API } from '../../../app/constants/const'
import dayjs from 'dayjs'

function convertToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
const SUBMITED_STEP = 8
const maxSteps = 10

const PreAssessmentForm = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [activeStep, setActiveStep] = useState(0)

  const {
    getValues,
    control,
    reset,
    trigger,
    watch,
    formState: { errors }
  } = useForm()

  const renderCurrentView = () => {
    switch (activeStep) {
      case 0:
        return <WelcomeStep />
      case 1:
        return <ContactDetailStep control={control} errors={errors} />
      case 2:
        return <GenderStep control={control} errors={errors} />
      case 3:
        return <MedicalEvidenceStep control={control} errors={errors} watch={watch} />
      case 4:
        return <InformCheckbox control={control} errors={errors} watch={watch} />
      case 5:
        return <ExistingAssistiveStep control={control} errors={errors} watch={watch} />
      case 6:
        return <LaptopSpecification control={control} errors={errors} watch={watch} />
      case 7:
        return <DsaAwardStep control={control} errors={errors} watch={watch} />
      case 8:
        return <TravelStep control={control} errors={errors} watch={watch} />
      case 9:
        return <FinishStep />
      default:
        return <WelcomeStep />
    }
  }

  const handleNext = async () => {
    const isValid = await trigger()
    if (isValid) {
      if (activeStep === SUBMITED_STEP) {
        setIsLoading(true)
        const values = getValues()
        const filesToCheck = ['medicalEvidence', 'previousAssessment']
        const dateFields = ['courseStartDate', 'prevAssessment']
        const promises = []

        filesToCheck.forEach(fieldName => {
          if (values[fieldName] instanceof File) {
            promises.push(
              convertToBase64(values[fieldName])
                .then(base64Data => ({ fieldName, base64Data }))
                .catch(error =>
                  console.error(`Error while converting ${fieldName} to Base64:`, error)
                )
            )
          }
        })

        Object.keys(values).forEach(item => {
          if (typeof values[item] === 'boolean') {
            values[item] = values[item] ? 'Yes' : 'No'
          }
        })
        dateFields.forEach(item => {
          if (values[item] !== undefined || values[item]) {
            values[item] = dayjs(values[item]).format('DD/MM/YYYY')
          } else {
            values[item] = ''
          }
        })
        Promise.all(promises)
          .then(fileDataArray => {
            const data = {
              service_id: process.env.REACT_APP_YOUR_SERVICE_ID,
              template_id: process.env.REACT_APP_YOUR_TEMPLATE_ID_PRE_ASSESSMENT_FORM,
              user_id: process.env.REACT_APP_YOUR_PUBLIC_KEY,
              template_params: { ...values }
            }

            fileDataArray.forEach(fileData => {
              data.template_params[fileData.fieldName] = fileData.base64Data
            })

            fetch(EMAILJS_API, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
              },
              body: JSON.stringify(data)
            })
              .then(result => {
                console.log(result.statusText, 'success')
                const newActiveStep = activeStep === maxSteps ? activeStep : activeStep + 1
                setActiveStep(newActiveStep)
                toast.success('Your pre-assessment form has been successfully submitted!')
                reset()
              })
              .catch(error => {
                console.log(error.statusText, 'Error')
                toast.error('Oops, Something went wrong, try again later!')
                setActiveStep(0)
                reset()
              })
          })
          .catch(error => {
            console.error('Error while processing files:', error)
          })
      } else {
        const newActiveStep = activeStep === maxSteps ? activeStep : activeStep + 1
        setActiveStep(newActiveStep)
      }
    }
  }

  const handleBack = () => {
    setActiveStep(activeStep - 1)
  }

  return (
    <Container>
      <Wrapper>
        <FormWrapper>
          <form id='myForm'>{renderCurrentView()}</form>
          <StepperWrapper>
            <MobileStepper
              steps={maxSteps}
              position='static'
              activeStep={activeStep}
              nextButton={
                <Button
                  sx={{
                    maxWidth: '289px',
                    width: '100%',
                    height: '54px',
                    backgroundColor: '#F4CD00',
                    borderRadius: '31px',
                    fontFamily: 'Open Sans Regular',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '20px',
                    lineHeight: '26px',
                    color: '#183561',
                    textTransform: 'none',
                    ':hover': { backgroundColor: '#F4CD00' },
                    '@media (max-width: 750px)': {
                      fontWeight: 500,
                      fontSize: '15px',
                      lineHeight: '24px',
                      width: '100%',
                      height: '44px',
                      alignSelf: 'flex-end',
                      marginTop: '12px'
                    }
                  }}
                  variant='contained'
                  size='small'
                  onClick={handleNext}
                  disabled={activeStep === 9 || isLoading}>
                  {activeStep === 0 ? 'Start pre-assessment form' : 'Next'}
                </Button>
              }
              backButton={
                <Button
                  sx={{
                    display: activeStep === 0 ? 'none' : 'block',
                    maxWidth: '289px',
                    width: '100%',
                    height: '54px',
                    backgroundColor: '#F4CD00',
                    borderRadius: '31px',
                    fontFamily: 'Open Sans Regular',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '20px',
                    lineHeight: '26px',
                    color: '#183561',
                    textTransform: 'none',
                    ':hover': { backgroundColor: '#F4CD00' },
                    '@media (max-width: 750px)': {
                      fontWeight: 500,
                      fontSize: '15px',
                      lineHeight: '24px',
                      width: '100%',
                      height: '44px',
                      alignSelf: 'flex-end',
                      marginTop: '12px'
                    }
                  }}
                  variant='contained'
                  size='small'
                  onClick={handleBack}
                  disabled={activeStep === 0 || activeStep === 9 || isLoading}>
                  Back
                </Button>
              }
            />
          </StepperWrapper>
        </FormWrapper>
      </Wrapper>
    </Container>
  )
}

export default PreAssessmentForm
