import { Box } from '@mui/material'
import styled from '@emotion/styled'

export const FirstBlockWrapper = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '74px',
  '@media (max-width: 1205px)': {
    paddingLeft: '0'
  },
  '@media (max-width: 750px)': {
    marginTop: '40px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}))

export const Content = styled(Box)(({ theme }) => ({
  height: '520px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  '@media (max-width: 750px)': {
    height: '100%'
  },
  '@media (max-width: 235px)': {
    marginBottom: '30px'
  }
}))

export const ImageWrapper = styled(Box)(({ theme }) => ({
  maxWidth: '520px',
  width: '100%',
  height: '520px',
  '& img': {
    width: '100%'
  },
  '@media (max-width: 750px)': {
    height: '290px',
    display: 'flex',
    justifyContent: 'center'
  },
  '@media (max-width: 235px)': {
    display: 'none'
  }
}))

export const FirstContentText = styled(Box)(({ theme }) => ({
  marginBottom: '24px',
  '@media (max-width: 750px)': {
    marginBottom: '16px'
  }
}))

export const FirstContentText2 = styled(Box)(({ theme }) => ({
  marginBottom: '24px',
  '@media (max-width: 750px)': {
    marginBottom: '16px'
  }
}))
