import { Box, Typography } from '@mui/material';
import styled from 'styled-components';

export const Wrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  marginBottom: '120px',
  '@media (max-width: 750px)': {
    marginBottom: '56px'
  },
  '& ul li::marker': {
    color: 'white'
  }
}));

export const ContentWrapper = styled(Box)(({ theme }) => ({
  marginTop: '42px',
  '@media (max-width: 750px)': {
    marginTop: '18px'
  }
}));

export const MrWrapper = styled(Box)(({ theme }) => ({
  margin: '24px 0'
}));

export const ImgWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '864px',
  '& img': {
    width: '100%'
  },

}));


export const Text = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  color: '#FFFFFF',
  '&.MuiTypography-semiBold': {
    fontFamily: 'Open Sans SemiBold',
    fontSize: '36px',
    lineHeight: '52px',
  },

  ///
  '@media (max-width: 750px)': {
    '&.MuiTypography-semiBold': {
      fontFamily: 'Open Sans SemiBold',
      fontSize: '24px',
      lineHeight: '40px',
    },

  }
}));
