import { Box, Typography } from '@mui/material'
import styled from 'styled-components'

export const Wrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  marginBottom: '77px',
  '@media (max-width: 750px)': {
    marginBottom: '56px'
  }
}))

export const ContentWrapper = styled(Box)(({ theme }) => ({
  marginTop: '42px',
  '@media (max-width: 750px)': {
    marginTop: '18px'
  }
}))

export const BoxStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '60px 80px',
  '@media (max-width: 750px)': {
    flexDirection: 'column-reverse',
    height: '350px',
    margin:'24px 0',
  },
  '@media (max-width: 550px)': {
    height: '100%',
  },
}))

export const ImgWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '396px',
  alignSelf: 'flex-end',
  '& img': {
    width: '100%'
  },
  '@media (max-width: 750px)': {
    alignSelf: 'flex-start'
  },
 
}))
export const ButtonWrapper = styled(Box)(({ theme }) => ({
  alignSelf: 'flex-start',

  '@media (max-width: 750px)': {
    alignSelf: 'flex-end'
  }
}))
