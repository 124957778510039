import React from 'react'
import { Box, Stack } from '@mui/material'
import { FormText } from '../PreAssessmentForm.styled'
import { ControlledTextField } from '../../../components/inputs/ControlledTextField'
import { ControlledDatePicker } from '../../../components/inputs/ControlledDatePicker'
import { ControlledCheckbox } from '../../../components/inputs/ControlledCheckbox'
import ControlledUploadFileField from '../../../components/inputs/ControlledUploadFile'
import { ControlledRadioButton } from '../../../components/inputs/ControlledRadioButton'

const hasAssessmentBeforeOptions = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' }
]

const MedicalEvidenceStep = ({ control, errors, watch }) => {
  const hasAssessmentBefore = watch('hasAssessmentBefore')

  return (
    <Box>
      <FormText variant='h1'>Medical Evidence and Previous Assessments</FormText>
      <Stack direction={'column'} spacing={3} sx={{ mt: 2 }}>
        <ControlledUploadFileField
          fullWidth
          control={control}
          name='medicalEvidence'
          label='Medical Evidence is required to proceed with booking your assessment appointment*'
          inputProps={{
            accept: '.pdf'
          }}
          rules={{ required: true }}
          error={!!errors.medicalEvidence}
          helperText='File upload is required'
        />
        <FormText variant='h1'>Have you had a Needs Assessment before?</FormText>
        <ControlledRadioButton
          name='hasAssessmentBefore'
          options={hasAssessmentBeforeOptions}
          control={control}
          defaultValue={'no'}
        />
        {hasAssessmentBefore === 'yes' && (
          <>
            <ControlledDatePicker
              variant='standard'
              control={control}
              name='prevAssessment'
              label='When did you have your previous Needs Assessment?*'
              rules={{ required: true, minLength: 3 }}
              error={!!errors.prevAssessment}
              helperText='When did you have your previous Needs Assessment?'
            />
            <ControlledUploadFileField
              fullWidth
              control={control}
              name='previousAssessment'
              label='Please attach your previous Needs Assessment Report.*'
              inputProps={{
                accept: '.pdf'
              }}
              rules={{ required: true }}
              error={!!errors.previousAssessment}
              helperText='File upload is required'
            />
          </>
        )}
        <FormText variant='h1'>Do you have any additional requirements?</FormText>
        <ControlledCheckbox
          name='blueBadgeParkingAdditionalRequirements'
          control={control}
          label={'Blue badge parking'}
        />
        <ControlledCheckbox
          name='bslInterpreterAdditionalRequirements'
          control={control}
          label={'BSL interpreter'}
        />
        <ControlledTextField
          variant='standard'
          control={control}
          name='medicalEvidenceOpenComment'
          label='Is there anything else you would like the Assessor or our Admin team to be aware of?'
          multiline
          minRows={2}
        />
      </Stack>
    </Box>
  )
}

export default MedicalEvidenceStep
