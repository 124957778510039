import { Box } from '@mui/material';
import React from 'react';

import styled from 'styled-components';

const BoxStyled = styled(Box)(({ theme }) => ({
  maxWidth: '864px',
  width: '100%',
  margin: '0 auto',
  boxSizing: 'border-box',
  '@media (max-width: 900px)': {
    padding: '0 16px'
  },

}));


const Container = ({ children }) => {
  return (
    <BoxStyled sx={{ maxWidth: '864px', width: '100%', margin: '0 auto', boxSizing: 'border-box' }}>
      {children}
    </BoxStyled>
  );
};

export default Container;
