import { Box, IconButton, ListItemText } from '@mui/material';
import CustomLink from '../Link/Link';
import Button from '@mui/material/Button';
import { display, height, styled, width } from '@mui/system';

export const UlStyled = styled(`ul`)(({ theme }) => ({
  margin: 0,
  padding: 0,
}));

export const Wrapper = styled(Box)(({ theme }) => ({
  backgroundColor: '#224B8A',
  width: '100%',
  height: '104px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '@media (max-width: 900px)': {
    height: '36px'
  }
}));

export const AppBar = styled(Box)(({ theme }) => ({
  maxWidth: '1440px',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '40px 64px',
  height: '24px',
  '@media (max-width: 900px)': {
    padding: '8px 9px',
    height: '24px',
  }
}));

export const LogoWrapper = styled(Box)(({ theme }) => ({
  width: '164px',
  height: '24px',
  '& img': {
    width: '164px',
    height: '24px'
  },
  '@media (max-width: 900px)': {
    '& img': {
      marginTop: '4px',
      width: '137px',
      height: '21px',
    },
  }
}));



export const NavDesktopWrapper = styled(Box)(({ theme }) => ({
  maxWidth: '638px',
  width: '100%',
  height: '24px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '@media (max-width: 900px)': {
    display: 'none'
  }
}));



export const Burger = styled(IconButton)(({ theme }) => ({
  display: 'none',
  '@media (max-width: 900px)': {
    display: 'block',
    width: '40px',
    height: '36px',
    color: '#FFFFFF'
  }
}));


export const LinkStyled = styled(CustomLink)(({ theme }) => ({
  fontFamily: 'Open Sans Regular',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '18px',
  lineHeight: '30px',
  color: '#183561'
}));

export const ButtonStyled = styled(CustomLink)(({ theme }) => ({
  fontFamily: 'Open Sans Regular',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '18px',
  lineHeight: '34px',
  toUpperCase: 'none',
  color: '#FFFFFF',
  textTransform: 'none',
  '&:hover': {
    color: '#F4CD00'
  }
}));

