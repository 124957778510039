import { Box, Container } from '@mui/material';
import React from 'react';
import { MrWrapper, Wrapper } from './TermsConditions.styled';
import { GeneralText } from '../../components/General/Text';

const TermsConditions = () => {
  return (
    <Container>
      <Wrapper>
        <GeneralText variant='h1'>Study Tech Ltd DSA Terms & Conditions</GeneralText>
        <GeneralText variant='h2'>Introduction</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>
            Study Tech Ltd provides goods and services through the Disabled Student’s Allowance (DSA) in accordance with our contract with the Student Loans Company (SLC).

          </GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>
            These Terms and Conditions (T&Cs) describe our services to you and apply to all customers who receive services from Study Tech. Please read these T&Cs carefully before obtaining any goods or services.
          </GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>
            By accessing goods and services provided by Study Tech you agree to be bound by these T&Cs and our Privacy Policy.
          </GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>
            A failure to comply with any of these T&Cs may result in Study Tech withdrawing any further support.
          </GeneralText>
        </MrWrapper>
        <GeneralText variant='h2'>Needs Assessment Services</GeneralText>
        <GeneralText variant='h4'>Booking a needs assessment appointment</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>
            You can choose a needs assessment appointment at a time and date that suits you, from the list of available appointments provided through the Student App or Admin team.
          </GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>
            Appointments can be booked through the Student App or over the phone and via email with our Admin team.
          </GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>
            You must inform us of any accessibility needs ahead of the appointment either in the relevant section of the Pre-Assessment form, or via email or phone with our Admin team. Failure to inform us of any specific accessibility needs with enough notice may result in your needs assessment appointment being rescheduled and delayed. This is particularly important for students requiring support such as a BSL interpreter.
          </GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>
            You are welcome to bring someone with you to the assessment. Please let your assessor or the Admin team know ahead of the appointment if this is the case.
          </GeneralText>
        </MrWrapper>
        <GeneralText variant='h2'>Attending a needs assessment appointment</GeneralText>
        <GeneralText variant='h4'>Online</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>
            If you are attending your needs assessment appointment online you will be sent a link for the appointment ahead of the booked date and time.
          </GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>
            It is your responsibility to ensure you have a working internet connection and device on which to attend the appointment. We would highly recommend that you use a computer or tablet if possible, and not a smartphone.
          </GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>
            Please ensure that the environment around you is free from noise and distractions.
          </GeneralText>
        </MrWrapper>
        <GeneralText variant='h4'>In Person</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>
            If you are attending your needs assessment appointment in person you must ensure that you are able to travel to the assessment location and arrive on time.
          </GeneralText>
        </MrWrapper>
        <GeneralText variant='h4'>Running Late</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>
            Please telephone or email us if you are running late. The assessor will typically wait for you for 15 minutes, after which the appointment is listed as No Show. We may be able to still go ahead with your appointment if you are more than 15 minutes late, however that is dependent on the complexity of your needs and this is at the discretion of your assessor.
          </GeneralText>
        </MrWrapper>

        <GeneralText variant='h2'>Cancelling a needs assessment appointment</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>
            Needs assessment appointments can be cancelled either by phone or email with the Admin team.
          </GeneralText><br />
          <GeneralText variant='p'>
            Please note that if you cancel your appointment, we cannot guarantee immediate availability for any future appointments.</GeneralText>
        </MrWrapper>

        <GeneralText variant='h4'>With 24 hours notice</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>
            If you cancel your appointment with more than 24 work hours notice (ie excluding weekends) of the booked date and time the Admin team will work with you to rearrange the appointment for a more suitable time.
          </GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>
            For example, 24 hours’ notice for appointment booked on Monday at 9am is the previous Friday at 8:59 am.
          </GeneralText>
        </MrWrapper>
        <GeneralText variant='h4'>Without 24 hours notice</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>
            If you cancel your appointment with less than 24 work hours notice of the booked date and time, or if you do not arrive for your appointment with no advance notice, please note a cancellation fee will apply, which is deducted from your overall general DSA allowance amount.
          </GeneralText>
        </MrWrapper>
        <GeneralText variant='h2'>Your Report and Recommendations</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>
            We will only assess for the conditions listed in your DSA1 letter, or if you provide additional information at the time of assessment.
          </GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>
            Recommendations are subject to certain restrictions imposed by the funding bodies. For example, recommendations must match your funding body’s policy regarding allowable technology to support your condition.
          </GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>
            You have the option to review your needs assessment report before it is sent to your funding body for approval. It will be emailed to you and any feedback must be received from you within 5 working days, after which the report will be automatically sent to your funding body.
          </GeneralText>
        </MrWrapper>
        <GeneralText variant='h2'>Sharing Your Report with your Higher Education Provider (HEP)</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>
            The assessor will ask you if you would like your report to be shared with your University or College Disability team. If you choose to share your report, it will be sent to your University or College Disability team confidentially at the same time as it is sent to your funding body.
          </GeneralText>
        </MrWrapper>
        <GeneralText variant='h2'>Additional Consent</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>
            We may request additional optional consent from you from time to time. The type of consent we may request depends on the service we offer to you and may change from time to time. We may request the following additional consent from you:
          </GeneralText><br />
          <GeneralText variant='p'>
            - Assessor training or observation consent<br />
          </GeneralText>
          <GeneralText variant='p'>
            - Consent to share with HEP<br />
          </GeneralText>
        </MrWrapper>
        <GeneralText variant='h2'>Supply of Specialist Equipment and Software</GeneralText>
        <GeneralText variant='h4'>Placing an order</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>
            Orders for recommended goods and services can only be placed on receipt of a DSA2 letter from your funding body.
          </GeneralText>
        </MrWrapper>

        <MrWrapper>
          <GeneralText variant='p'>
            Although you have the option to upgrade some of the items you may have been recommended, you cannot substitute items without the express permission of your funding body.
          </GeneralText>
        </MrWrapper>
        <GeneralText variant='h4'>Upgrades</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>Upgrades are available for some items, such as computers, printers and headsets.</GeneralText><br />
          <GeneralText variant='p'>You must pay for any additional costs associated with the upgrade, over the original amount approved by the funding body.</GeneralText><br />
          <GeneralText variant='p'>Quotes for upgrades are valid for 30 days, after which a new quote must be issued.</GeneralText>
        </MrWrapper>
        <GeneralText variant='h4'>Payment</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>Payment for your order is made via secure payment link.</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>You will be charged a £200 contribution towards any computer recommended as per funding body policy.</GeneralText>
        </MrWrapper>
        <GeneralText variant='h4'>Right to cancel</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>You may cancel your order up 24 hours in advance of the date of dispatch, excluding upgrades or custom orders. Please phone our Admin team to cancel your order.</GeneralText>
        </MrWrapper>
        <GeneralText variant='h4'>Consent to Share</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>To provide you with seamless service, it is necessary for us to share elements of your data with some 3rd party vendors. This data share is strictly limited to information necessary for the specific purpose of delivering our services and ensuring efficient communication regarding your orders or requests.</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>The full list of vendors that request customer data is available in our Privacy Policy, your data will not be shared with any other third parties beyond those listed, and all parties involved are required to handle your personal data in compliance with UK data protection laws, including the Data Protection Act 2018 and the UK GDPR.</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>By using our services, you agree that we can share this data.</GeneralText>
        </MrWrapper>
        <GeneralText variant='h4'>Delivery</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>Once your order has been confirmed and the necessary procurement process completed, your order will be booked in for delivery.</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>Delivery can be made to any address in the UK. We cannot deliver outside of the UK. Delivery is made by an insured courier (e.g. DPD) or an installation engineer, Monday to Friday, 7am to 12pm.</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>We are unable to arrange delivery to a neighbour or to leave items in a safe place, but this may be arranged directly with the courier at your own risk – please see below detail in DPD Deliveries.</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>The delivery address you provide must be accurate. Delivery details are confirmed prior to confirming your order, however in exceptional circumstances changes to delivery addresses can be communicated to the courier once you receive your delivery notification from them.</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>We will endeavour to deliver your order on the date and time agreed, however this is not guaranteed. Where the delivery date and time vary we will work with you to rebook the delivery at a date and time that is convenient for you.</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>Please note that some remote locations, such as the Highlands and Islands, take longer to deliver, usually up to 2 working days.</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>We cannot accept any liability for any loss or damage to the goods once they have been delivered. You will become the owner of the goods on receipt and will be held at your own risk.</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>Any problems with delivery or order discrepancies must be reported to Study Tech within 2 working days of delivery to be rectified. We will not process any claims made outside 2 working days of delivery.</GeneralText>
        </MrWrapper>
        <GeneralText variant='h4'>DPD Deliveries</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>Once we scheduled your delivery, you will be able to use the DPD app or website to make changes to your delivery. You are able to change your delivery date, address, redirect the parcel to neighbour, or to leave it in a safe place. If you choose to select this option with DPD then you do so at your own risk.</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>If you make any changes to your delivery via the DPD app, we will not accept liability, should the goods be lost, stolen or otherwise damaged.</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>DPD website and app are third party services. Study Tech does not guarantee any availability, features or withdrawal of features by third parties.</GeneralText>
        </MrWrapper>
        <GeneralText variant='h4'>Refunds</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>Refunds may be provided on:</GeneralText><br />
          <ul>
            <li><GeneralText variant='p'>Orders delivered within 30 days of the refund request.</GeneralText></li>
            <li><GeneralText variant='p'>Standard equipment that has not been opened, used and is in a resaleable condition, including packaging and documentation.</GeneralText></li>
          </ul>
          <GeneralText variant='p'>Refunds cannot be provided for:</GeneralText><br />
          <ul>
            <li><GeneralText variant='p'>Upgraded or custom equipment.</GeneralText></li>
            <li><GeneralText variant='p'>Software licence keys that have been issued</GeneralText></li>
          </ul><br />
          <GeneralText variant='p'>Our full Refund Policy can be found <a href='/refund-policy' target='_blank' className='email-link' aria-label='/refund-policy'>here</a>. Please read it before making a refund request.</GeneralText>
        </MrWrapper>
        <GeneralText variant='h2'>Assistive Technology Training Services</GeneralText>
        <GeneralText variant='h4'>Booking a training session</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>You can book training sessions at a time and date that suits you, from the available sessions provided by the App or Admin team.</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>Sessions can be booked through the Student App, over the phone with our Admin team or directly with your assigned trainer.</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>You must inform us of any accessibility requirements ahead of the session, either with your trainer or our Admin team, via email or phone.</GeneralText>
        </MrWrapper>

        <GeneralText variant='h4'>Attending a training session</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'><b>Online</b></GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>If you are attending your training session online you will be sent a link for the session 24 hours ahead of the booked date and time.</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>It is your responsibility to ensure you have a working internet connection and device on which to attend the session. . We would highly recommend that you use a computer or tablet if possible, and not a smartphone.</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>Please ensure that the environment around you is free from noise and distractions.</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'><b>In Person</b></GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>If you are attending your training session in person you must ensure that you are able to travel to the session location and arrive on time.</GeneralText>
        </MrWrapper>
        <GeneralText variant='h4'>Cancelling a training session</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>Training sessions can be cancelled by contacting the Training Admin Team or your assigned trainer.</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'><b>With 24 hours notice</b></GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>If you cancel your training session with more than 24 work hours notice of the booked date and time the session will be rescheduled.</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'><b>Without 24 hours notice</b></GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>You can only cancel 2 training sessions with late notice for the duration of the support. For the first 2 cancellations, the hours will not be deducted from your training allowance. After this, if more sessions are cancelled at short notice (or you do not turn up to them), then the session will be forgone and the hours will be deducted from the allowance.</GeneralText>
        </MrWrapper>

        <GeneralText variant='h2'>Technical Support and Helpdesk Services</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>Technical Support services are available on the equipment and software provided to you with DSA funding, for the duration of your studies and with Annual Service Charge in place.</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>This includes:</GeneralText>
          <ul>
            <li><GeneralText variant='p'>Remote support via telephone, email or screenshare</GeneralText></li>
            <li><GeneralText variant='p'>Repairs for hardware or component failure within 1 year manufacturer warranty</GeneralText></li>

          </ul>
        </MrWrapper>

        <GeneralText variant='h4'>Software issues</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>Software that is faulty or not working as expected will be subject to remote troubleshooting. Please contact the Technical Support team to organise a troubleshooting session.</GeneralText>
        </MrWrapper>

        <GeneralText variant='h4'>Faulty Equipment</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>Hardware that is faulty or not working as expected will be subject to remote troubleshooting in the first instance. We will not be able to collect your machine until a troubleshooting session took place. Please contact the Technical Support team to organise a troubleshooting session.</GeneralText>
        </MrWrapper>

        <GeneralText variant='h4'>Dead on Arrival Equipment</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>Equipment that develops a fault immediately after it was supplied can be classified as Dead on Arrival (DOA). DOA status needs to be confirmed by the manufacturer before a replacement  can take place. If the manufacturer considers the fault repairable, a repair will be conducted instead.</GeneralText>
        </MrWrapper>
        <GeneralText variant='h4'>Within 1 year manufacturer’s warranty</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>Hardware such as computers and printers that develop faults within the 12 month manufacturer warranty will be handled through the manufacturer’s warranty process.</GeneralText>
        </MrWrapper>
        <GeneralText variant='h4'>Outside manufacturer’s warranty</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>Hardware that develops a fault that sits outside the manufacturer warranty – either beyond the 12 month support period or outside the warranty cover – will be handled by the Study Tech Technical Support team. <br />Where a repair requires an in person inspection this will be organised by the Technical Support team.</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>The device must be securely and appropriately packaged and all files backed up. We will not accept liability for any damage in transit or loss of data.</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>If your DSA order was subject to an Annual Service Charge, equipment faults outside of the manufacturer’s warranty will be covered by a Repair or Replace process.</GeneralText>
        </MrWrapper>
        <GeneralText variant='h4'>Loan equipment</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>Where your device has had to be returned to base for a repair a loan device may be offered to you.</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>Loan devices must only be used for study and must be kept clean and cared for.</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>When returning the loan device it must be securely packaged to prevent damage. It must also have any personal data removed. We are not responsible for any loss of data stored on these devices.</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>Any loan device returned to Study Tech in disrepair may result in a fine.</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>Loan devices must be returned before your equipment is dispatched to you.</GeneralText>
        </MrWrapper>
        <GeneralText variant='h4'>Backing up your data</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>We recommend you always use Cloud Storage for your data. Office 365 offers 1TB free storage.</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>Should your machine develop a fault, you must back up any files and documents you store locally. If this is not possible, for example your laptop has a broken screen and does not boot up, please let our Technical Support team know. We will make every effort to recover your data, however we cannot accept any responsibility for data loss.</GeneralText>
        </MrWrapper>
        <GeneralText variant='h2'>Repair and Replace Service</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>Repair and Replace service provides additional cover on hardware provided to you with DSA funding, for the duration of your studies.</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>This includes:</GeneralText>
          <ul>
            <li><GeneralText variant='p'>Repairs for hardware or component failure outside of 1 year manufacturer warranty</GeneralText></li>
            <li><GeneralText variant='p'>Cover for stolen or damaged equipment</GeneralText></li>
          </ul>
        </MrWrapper>
        <GeneralText variant='h4'>Outside manufacturer’s warranty</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>Hardware that develops a fault that sits outside the manufacturer warranty – either beyond the 12 month support period or outside the warranty cover – will be handled by the Study Tech Technical Support team.</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>Where a repair requires a return to base this will be organised by the Technical Support team.</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>The device must be securely and appropriately packaged. We will not accept liability for any damage in transit.</GeneralText>
        </MrWrapper>
        <GeneralText variant='h4'>Stolen equipment</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>If your equipment has been stolen you must complete a claim form, including a crime reference number. The claim will then be considered by the funding body for further action.</GeneralText>
        </MrWrapper>
        <GeneralText variant='h2'>Complaints</GeneralText>
        <GeneralText variant='h4'>Policy</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>Our full complaints policy and procedure can be found <a href='/complaints-policy' target='_blank' className='email-link' aria-label='complaints-policy'>here</a>.</GeneralText>
        </MrWrapper>
        <GeneralText variant='h4'>Procedure</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>Complaints must be made in writing, sent either to the postal or email address below.</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>On receipt of a complaint you will receive an acknowledgement within 24 hours, and a response within 5 working days.</GeneralText>
        </MrWrapper>
        <GeneralText variant='h4'>Contact information</GeneralText><br />
        <GeneralText variant='p'><b>Address</b></GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>Study Tech Ltd., Equilibrium House, Mansion Close, Moulton Park, Northampton, NN3 6RU.</GeneralText>
        </MrWrapper>
        <GeneralText variant='p'><b>Email address</b></GeneralText>
        <MrWrapper>
          <a href='mailto:complaints@study.tech' className='email-link' aria-label='complaints@study.tech'>complaints@study.tech</a>
        </MrWrapper>
        <GeneralText variant='h2'>Safeguarding and Consent to share</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>There may be occasions, where we will share your data for legal, compliance or safeguarding reasons. We will not ask for your consent, or inform you when sharing your data for these reasons.</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>If we believe there is a safeguarding issue, we may contact your HEP/ Disability adviser, the relevant authorities, or any other contact available to us on your file. Any information shared in such circumstances will be managed with sensitivity and in line with safeguarding protocols, and only necessary information will be shared to protect your wellbeing</GeneralText>
        </MrWrapper>
        <GeneralText variant='h2'>Conduct</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>We expect all Study Tech staff and customers to be considerate and polite.</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>Verbal abuse of any kind will not be tolerated and will result in the call or appointment being immediately terminated.</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>Study Tech provided systems must not be used to upload or share any inappropriate content.</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>We reserve the right to refuse services for any customer who is threatening or inappropriate.</GeneralText>
        </MrWrapper>

        <GeneralText variant='h2'>Use of your data – GDPR and Privacy</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>How we use and process your data is guided by our Privacy Policy. Please refer to privacy policy.</GeneralText>
        </MrWrapper>
        <GeneralText variant='h2'>Updates to the Terms and Conditions</GeneralText>
        <MrWrapper>
          <GeneralText variant='p'>These Terms and Conditions were last updated and became effective on 18th November 2024.</GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>We may modify these Terms and Conditions for any reason, at any time, by posting a new version on our website. Please check periodically for changes. Your continued use of our services following the posting of modified Terms and Conditions will act as agreement of the Terms and Conditions in place at the time.</GeneralText>
        </MrWrapper>
      </Wrapper>
    </Container>
  );
};

export default TermsConditions;
