import React from "react";
import { AppBar, Burger, LogoWrapper, Wrapper } from "./Navbar.styled";
import CustomLink from "../Link/Link";
import Logo from '../../../assets/icons/navbar/Logo.svg';
import MenuIcon from '@mui/icons-material/Menu';
import NavDesktop from "./Nav-desktop/NavDesktop";
import MobileMenu from "./Mobile-menu/MobileMenu";

const Navbar = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);


  const handleDrawerToggle = () => {
    setMobileOpen(prevState => !prevState);
  };


  return (
    <>
      <Wrapper>
        <AppBar>
          <LogoWrapper >
            <CustomLink to='/' tabIndex={0} label="Study Tech home">
              <img src={Logo} alt='Study Tech home' />
            </CustomLink>
          </LogoWrapper>
          <NavDesktop />
          <Burger
            aria-label='open menu'
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </Burger>
        </AppBar>
      </Wrapper>
      <MobileMenu handleDrawerToggle={handleDrawerToggle} open={mobileOpen} />
    </>
  );
};

export default Navbar;
