import React from 'react'
import { Controller } from 'react-hook-form'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TextField, Box } from '@mui/material'
import { dayjs } from 'dayjs'

export const ControlledDatePicker = ({
  control,
  name,
  label,
  required = false,
  rules,
  error,
  helperText,
  ...datePickerProps
}) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {error && <Box sx={{ alignSelf: 'flex-end', color: '#FF9E9E' }}>{helperText}</Box>}
      <Controller
        rules={rules}
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <DatePicker
            name={name}
            label={label}
            inputFormat='dd/MM/yyyy'
            format={'dd/MM/yyyy'}
            value={value || null}
            size='small'
            onChange={onChange}
            sx={{
              fontFamily: 'Open Sans Regular',
              backgroundColor: '#f5f5fa',
              borderRadius: '15px',
              paddingX: 2,
              '& .MuiFormLabel-root': {
                fontFamily: 'Open Sans Regular',
                color: value ? '#FFF' : '#6C6C99',
                top: value ? '-20px' : '-8px',
                paddingX: 2,
                fontSize: '16px',
                fontWeight: value ? 600 : 400,
                '&.Mui-focused': {
                  color: '#FFF',
                  top: '-20px',
                  fontSize: '16px',
                  fontWeight: 600
                }
              }
            }}
            slotProps={{
              textField: {
                variant: 'standard',
                InputProps: { disableUnderline: true, name: name },
                required
              }
            }}
            {...datePickerProps}
            renderInput={params => (
              <TextField
                size='small'
                name={name}
                {...params}
                fullWidth
                helperText={null}
                value={value ? dayjs(value).format('DD/MM/YY') : ''}
              />
            )}
          />
        )}
      />
    </Box>
  )
}
