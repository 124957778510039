import { Box, Typography, styled } from '@mui/material';


export const Wrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  marginBottom: '120px',
  '@media (max-width: 750px)': {
    marginBottom: '56px'
  }
}));

export const ContentWrapper = styled(Box)(({ theme }) => ({
  marginTop: '24px',
}));



/////
export const MainWrapper = styled(Box)(({ theme }) => ({
  maxWidth: '1230px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  '@media (max-width: 750px)': {
    alignItems: 'center'
  }
}));
export const WrapperFooterMain = styled(Box)(({ theme }) => ({
  maxWidth: '1230px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '24px 0 120px 0',
}));

export const MainTitle = styled(Box)(({ theme }) => ({
  paddingLeft: '115px',
  maxWidth: '864px',
  width: '100%',
  '@media (max-width: 1205px)': {
    paddingLeft: '0'
  },
  '@media (max-width: 750px)': {
    maxWidth: '433px'
  }
}));

export const Text = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  fontFamily: 'Open Sans Regular',
  color: '#FFFFFF',
  '&.MuiTypography-title': {
    fontFamily: 'Open Sans Bold',
    fontSize: '32px',
    lineHeight: '35px',
    color: '#183561'
  },
  '&.MuiTypography-subtitle': {
    fontSize: '24px',
    lineHeight: '30px'
  },
  '&.MuiTypography-subtitleSemibold': {
    fontFamily: 'Open Sans SemiBold',
    fontSize: '32px',
    lineHeight: 'auto',
    marginBottom: '30px'
  },
  '&.MuiTypography-pBold': {
    fontFamily: 'Open Sans Bold',
    fontSize: '18px',
    lineHeight: '30px'
  },
  '&.MuiTypography-pSemiBold': {
    fontFamily: 'Open Sans SemiBold',
    fontSize: '16px',
    lineHeight: '24px',
    marginTop: '16px'
  },

  ///
  '@media (max-width: 750px)': {
    '&.MuiTypography-title': {
      fontSize: '18px',
      lineHeight: '24px'
    },
    '&.MuiTypography-subtitle': {
      fontSize: '16px',
      lineHeight: '30px'
    },
    '&.MuiTypography-subtitleSemibold': {
      fontSize: '18px',
      lineHeight: 'auto',
      marginBottom: '10px'
    },
    '&.MuiTypography-pBold': {
      fontSize: '16px',
      lineHeight: '24px'
    },
    '&.MuiTypography-pSemiBold': {
      fontSize: '14px',
      marginTop: '6px'
    },
  }
}));
