import React from 'react'
import { Typography, Box, Button, MobileStepper as _MobileStepper } from '@mui/material'
import { FormText } from '../PreAssessmentForm.styled'

const WelcomeStep = () => {
  return (
    <Box>
      <FormText variant='h1'>Welcome to Study Tech</FormText>
      <Box sx={{ mt: 3 }}>
        <FormText variant='p'>
          We need to obtain some more information from you before you can book your Needs Assessment
          Appointment.
        </FormText>
      </Box>
      <Box sx={{ mt: 2 }}>
        <FormText variant='p'>
          This form should take you about 10 minutes to complete. This form allows your assessor to
          prepare for the Needs Assessment Appointment, so please take the time to complete it as
          fully as possible.
        </FormText>
      </Box>
    </Box>
  )
}

export default WelcomeStep
