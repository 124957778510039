import * as React from 'react';
import {
  AppBarWrapper,
  WrapperMobile,
} from './AppBar.styled';
import { useCurrentScreenWidth } from '../../../../hooks/screenSize/useCurrentSceenWidth';

export default function AppBar({ mobileImage, image, label }) {
  const [screenWidth] = useCurrentScreenWidth();
  return screenWidth <= 750 ? (
    <WrapperMobile>
      <img src={mobileImage} alt={label} />
    </WrapperMobile>
  ) : (
    <>
      <AppBarWrapper>
        <img src={image} alt={label} />
      </AppBarWrapper>
    </>
  );
}
