import React from 'react'
import { Box } from '@mui/material'
import AppBar from './AppBar/AppBar'
import ContainerStyled from '../../components/Containers/Container'
import { MainWrapper } from '../../components/Containers/MainWrapper.styled'
import MainCards from './MainCards/MainCards'
import { AppBarText } from './AppBar/AppBar.styled';

const Main = () => {
  return (
    <Box>
      <AppBar />
      <ContainerStyled>
        <MainWrapper>
          <AppBarText variant='h1'>Learning tailored to you</AppBarText>
          <MainCards />
        </MainWrapper>
      </ContainerStyled>
    </Box>
  )
}

export default Main
