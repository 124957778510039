import * as React from 'react';
import BackgroundWeb from '../../../../assets/images/home/BackgroundWeb.svg';
import BackgrounMobile from '../../../../assets/images/home/BackgrounMobile.svg';
import {
  AppBarWrapper,
  WrapperMobile,
} from './AppBar.styled';
import { useCurrentScreenWidth } from '../../../../hooks/screenSize/useCurrentSceenWidth';

export default function AppBar() {
  const [screenWidth] = useCurrentScreenWidth();
  return screenWidth <= 750 ? (
    <WrapperMobile>
      <img src={BackgrounMobile} alt='A person seated outdoors on a chair, looking at a laptop with a scenic mountain landscape in the background. The person is wearing a warm hat and sweater, and the misty mountains are bathed in soft, golden sunlight, creating a peaceful atmosphere.' />
    </WrapperMobile>
  ) : (
    <>
      <AppBarWrapper>
        <img src={BackgroundWeb} alt='A person seated outdoors on a chair, looking at a laptop with a scenic mountain landscape in the background. The person is wearing a warm hat and sweater, and the misty mountains are bathed in soft, golden sunlight, creating a peaceful atmosphere.' />
      </AppBarWrapper>
    </>
  );
}
