import { Box, Typography } from '@mui/material';
import styled from 'styled-components';

export const AppBarWrapper = styled(Box)(({ theme }) => ({
  maxHeight:'470px',
  height: '470px',
  width: '100%',
  position: 'relative',
  
  '& img': {
    width: '100%'
  },
  '@media (max-width: 1445px)': {
    '& img': {
      width: 'auto'
    }
  }
}));

export const WrapperMobile = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  backgroundColor: '#FFFFFF',
  '& img': {
    width: '100%'
  }
}));






export const AppBarText = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  color: '#FFFFFF',
  alignSelf: 'flex-start',
  '&.MuiTypography-h1': {
    fontFamily: 'Open Sans Bold',
    fontSize: '45px',
    lineHeight: '52px',
  },
  '@media (max-width: 750px)': {
    '&.MuiTypography-h1': {
      fontSize: '24px',
      lineHeight: 'normal',
    }
  }
}));
