import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';

export const Wrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  marginBottom: '120px',
  '& ul li::marker': {
    color: 'white'
  },
  '@media (max-width: 750px)': {
    marginBottom: '56px'
  }
}));

export const ContentWrapper = styled(Box)(({ theme }) => ({
  marginTop: '24px',
}));


export const ImgWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '864px',
  marginTop:'103px',
  '& img': {
    width: '100%'
  },
  '@media (max-width: 750px)': {
    marginTop:'40px',
  }
}));

export const FormWrapper = styled(Box)(({ theme }) => ({
  maxWidth: '864px',
  width: '100%',
  height: '100%',
  background: 'linear-gradient(129deg, #F4CD00 -79.81%, #183561 95.58%)',
  borderRadius: '30px',
  padding: '40px 24px 24px 24px',
  marginTop: '80px',
  '@media (max-width: 1205px)': {
    padding: '16px 24px',
    marginTop: '40px'
  }
}));



export const Text = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  fontFamily: 'Open Sans Regular',
  color: '#FFFFFF',
  '&.MuiTypography-p': {
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '30px'
  },

  ///
  '@media (max-width: 750px)': {
    '&.MuiTypography-p': {
      fontSize: '16px',
      lineHeight: '24px'
    },
  }
}));