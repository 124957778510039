import React from 'react'
import { Stack } from '@mui/material'
import { FormText } from '../PreAssessmentForm.styled'
import { ControlledTextField } from '../../../components/inputs/ControlledTextField'
import { ControlledCheckbox } from '../../../components/inputs/ControlledCheckbox'
import { ControlledHorizontalRadioButton } from './RadioButtonHorizontal'
const gradeOptions = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6', value: '6' }
]

const DsaAwardStep = ({ control, errors, watch }) => {
  const researchAndReadingGrade = watch('researchAndReadingGrade')
  const writingAndReviewingGrade = watch('writingAndReviewingGrade')
  const noteTakingGrade = watch('noteTakingGrade')
  const managingTimeGrade = watch('managingTimeGrade')

  return (
    <Stack direction={'column'} spacing={3}>
      <FormText variant='h1'>Research and reading*</FormText>
      <FormText variant='pDivider'>Help us measure the impact of the DSAs award.</FormText>
      <Stack direction={'column'} spacing={2}>
        <ControlledHorizontalRadioButton
          control={control}
          name={'researchAndReadingGrade'}
          options={gradeOptions}
          defaultValue={'1'}
        />
        {researchAndReadingGrade !== '6' && (
          <>
            <ControlledCheckbox control={control} name='readingAccuracy' label='Reading Accuracy' />
            <ControlledCheckbox
              control={control}
              name='reReadingForUnderstandingResearchAndReading'
              label='Re-reading for understanding'
            />
            <ControlledCheckbox
              control={control}
              name='concentrationResearchAndReading'
              label='Concentration'
            />
            <ControlledCheckbox
              control={control}
              name='timeManagementResearchAndReading'
              label='Time Management'
            />
            <ControlledCheckbox
              control={control}
              name='processingSpeedResearchAndReading'
              label='Processing Speed'
            />
            <ControlledCheckbox
              control={control}
              name='breakingDownNewWordsResearchAndReading'
              label='Breaking down new words'
            />
            <ControlledCheckbox
              control={control}
              name='energyLevelsResearchAndReading'
              label='Energy Levels'
            />
            <ControlledCheckbox
              control={control}
              name='motivationResearchAndReading'
              label='Motivation'
            />
            <ControlledCheckbox
              control={control}
              name='findingOrganisingMaterialResearchAndReading'
              label='Finding/Organising Material'
            />
            <ControlledCheckbox
              control={control}
              name='visualStressResearchAndReading'
              label='Visual Stress'
            />
            <ControlledTextField
              variant='standard'
              control={control}
              name='researchAndReadingComments'
              label='Open comments'
              multiline
              minRows={2}
            />
          </>
        )}
      </Stack>

      <FormText variant='h1'>Writing and reviewing academic work*</FormText>
      <Stack direction={'column'} spacing={3} sx={{ mt: 2 }}>
        <ControlledHorizontalRadioButton
          control={control}
          name={'writingAndReviewingGrade'}
          options={gradeOptions}
          defaultValue={'1'}
        />

        {writingAndReviewingGrade !== '6' && (
          <>
            <ControlledCheckbox
              control={control}
              name='referencingWritingAndReviewing'
              label='Referencing'
            />
            <ControlledCheckbox
              control={control}
              name='timeManagementWritingAndReviewing'
              label='Time Management'
            />
            <ControlledCheckbox
              control={control}
              name='handwritingWritingAndReviewing'
              label='Handwriting'
            />
            <ControlledCheckbox
              control={control}
              name='organisationWritingAndReviewing'
              label='Organisation'
            />
            <ControlledCheckbox
              control={control}
              name='confidenceWritingAndReviewing'
              label='Confidence'
            />
            <ControlledCheckbox control={control} name='typingWritingAndReviewing' label='Typing' />
            <ControlledCheckbox
              control={control}
              name='understandingTheBriefWritingAndReviewing'
              label='Understanding the brief'
            />
            <ControlledCheckbox
              control={control}
              name='SpellingGrammarStructureWritingAndReviewing'
              label='Spelling/Grammar/Structure'
            />
            <ControlledCheckbox
              control={control}
              name='planningWritingAndReviewing'
              label='Planning'
            />
            <ControlledCheckbox control={control} name='gettingStarted' label='Getting Started' />
            <ControlledTextField
              variant='standard'
              control={control}
              name='writingAndReviewingComments'
              label='Open comments'
              multiline
              minRows={2}
            />
          </>
        )}
      </Stack>

      <FormText variant='h1'>Note-taking in lectures and seminars*</FormText>
      <Stack direction={'column'} spacing={3} sx={{ mt: 2 }}>
        <ControlledHorizontalRadioButton
          control={control}
          name={'noteTakingGrade'}
          options={gradeOptions}
          defaultValue={'1'}
        />

        {noteTakingGrade !== '6' && (
          <>
            <ControlledCheckbox control={control} name='noteTakingNoteTaking' label='Note Taking' />
            <ControlledCheckbox control={control} name='typingNoteTaking' label='Typing' />
            <ControlledCheckbox
              control={control}
              name='energyLevelsNoteTaking'
              label='Energy Levels'
            />
            <ControlledCheckbox control={control} name='hearingNoteTaking' label='Hearing' />
            <ControlledCheckbox control={control} name='visionNoteTaking' label='Vision' />
            <ControlledCheckbox
              control={control}
              name='distractionNoteTaking'
              label='Distraction'
            />
            <ControlledCheckbox control={control} name='focusNoteTaking' label='Focus' />
            <ControlledCheckbox
              control={control}
              name='concentrationNoteTaking'
              label='Concentration'
            />
            <ControlledCheckbox
              control={control}
              name='activeListeningNoteTaking'
              label='Active Listening'
            />
            <ControlledCheckbox
              control={control}
              name='listeningAndWritingConcurrentlyNoteTaking'
              label='Listening and writing concurrently'
            />
            <ControlledTextField
              variant='standard'
              control={control}
              name='noteTakingComments'
              label='Open comments'
              multiline
              minRows={2}
            />
          </>
        )}
      </Stack>

      <FormText variant='h1'>Managing time and organising work*</FormText>
      <Stack direction={'column'} spacing={3} sx={{ mt: 2 }}>
        <ControlledHorizontalRadioButton
          control={control}
          name={'managingTimeGrade'}
          options={gradeOptions}
          defaultValue={'1'}
        />

        {managingTimeGrade !== '6' && (
          <>
            <ControlledCheckbox
              control={control}
              name='organisationManagingTime'
              label='Organisation'
            />
            <ControlledCheckbox
              control={control}
              name='procrastinationManagingTime'
              label='Procrastination'
            />
            <ControlledCheckbox
              control={control}
              name='motivationManagingTime'
              label='Motivation'
            />
            <ControlledCheckbox
              control={control}
              name='conceptTimeManagingTime'
              label='Concept of Time'
            />
            <ControlledCheckbox
              control={control}
              name='prioritisingManagingTime'
              label='Prioritising'
            />
            <ControlledCheckbox
              control={control}
              name='balancingStudyPersonalLifeManagingTime'
              label='Balancing Study/Personal Life'
            />
            <ControlledCheckbox
              control={control}
              name='energyLevelsManagingTime'
              label='Energy Levels'
            />
            <ControlledCheckbox control={control} name='overwhelmManagingTime' label='Overwhelm' />
            <ControlledTextField
              variant='standard'
              control={control}
              name='managingTimeComments'
              label='Open comments'
              multiline
              minRows={2}
            />
          </>
        )}
      </Stack>
    </Stack>
  )
}

export default DsaAwardStep
